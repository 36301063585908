import { mean, median, mode, min, max } from 'simple-statistics'
import * as turf from '@turf/turf';
import { ObjectTypes } from "./types";
import * as FaIcons from 'react-icons/fa';
import * as tbIcons from 'react-icons/tb';
import tinycolor from 'tinycolor2';
import { parseSVG, makeAbsolute } from 'svg-path-parser';

export class HighlightSVG {
    constructor(svgClass) {
        this.svgClass = svgClass;
    }

    getPolygonId(contact_key, objectsTree) {
        const isContact = (objectNode) => objectNode.key === contact_key;

        const findPolygonId = (objectNode) => {
            if (isContact(objectNode))
                return objectNode.properties.polygonId;

            if (objectNode.children) {
                var result;

                for (var cIndex in objectNode.children) {
                    var child = objectNode.children[cIndex];
                    result = findPolygonId(child);

                    if (result !== null)
                        return result;
                }
            }

            return null;
        }

        var polygonId;

        for (var oIndex in objectsTree) {
            var objectNode = objectsTree[oIndex]
            polygonId = findPolygonId(objectNode);

            if (polygonId !== null)
                break;
        }

        return polygonId;
    }


    selectSVG(contact_key, objectsTree) {
        let obj = document.querySelector("object");

        if (obj) {
            this.svgDoc = obj.contentDocument;
            let polygonIds = contact_key.map(key => this.getPolygonId(key, objectsTree)).flat();
            polygonIds.forEach(polygonId => {
                let selected = this.svgDoc.querySelector("#" + polygonId);
                if (selected !== null)
                    selected.classList.add(this.svgClass);
            });
        }
    }

    cleanSVG() {
        let obj = document.querySelector("object");

        if (obj) {
            this.svgDoc = obj.contentDocument;
            let oldSelecteds = [...this.svgDoc.querySelectorAll("." + this.svgClass)];

            if (oldSelecteds.length > 0) {
                oldSelecteds.forEach((object) => {
                    object.classList.remove(this.svgClass);
                })
            }
        }

        this.eraseContactPath();
    }

    eraseContactPath(){
        let obj = document.querySelector("object");

        if (obj) {
            this.svgDoc = obj.contentDocument;

            //eraseContactPath
            if (this.svgDoc.querySelector(".contact-path"))
                this.svgDoc.querySelector(".contact-path").remove();
        }
    }

    getIntersection(polygonIdObj1, polygonIdObj2, svgDoc) {
        const path1 = svgDoc.getElementById(polygonIdObj1);
        const path2 = svgDoc.getElementById(polygonIdObj2);
        const path1Points = [];
        const path2Points = [];
        const intersection = [];
        const pointIntersect = (p1, p2) => {
            p1.x = Math.round(p1.x);
            p1.y = Math.round(p1.y);
            p2.x = Math.round(p2.x);
            p2.y = Math.round(p2.y);
            return p1.x === p2.x && p1.y === p2.y;
        };

        for (let j = 0; j < path1.getTotalLength(); j++)
            path1Points.push(path1.getPointAtLength(j));

        for (let j = 0; j < path2.getTotalLength(); j++)
            path2Points.push(path2.getPointAtLength(j));

        for (let i = 0; i < path1Points.length; i++)
            for (let j = 0; j < path2Points.length; j++)
                if (pointIntersect(path1Points[i], path2Points[j]))
                    intersection.push(path1Points[i]);

        return intersection
    }

    drawContactPath(obj1, obj2, objects, contact){
        let obj = document.querySelector("object");

        this.eraseContactPath();

        if (obj) {
            let polygonIdObj1 = this.getPolygonId(obj1.original_key, objects);
            let polygonIdObj2 = this.getPolygonId(obj2.original_key, objects);
            let svgDoc = obj.contentDocument;

            if (polygonIdObj1 && polygonIdObj2) {
                let intersection;
                const parent = svgDoc.getElementById(polygonIdObj1).parentElement;
                const newContactPath = document.createElementNS(
                    "http://www.w3.org/2000/svg",
                    "g"
                );

                const addCircle = (cx, cy) => {
                    const circle = document.createElementNS(
                        "http://www.w3.org/2000/svg",
                        "circle"
                    );
                    circle.setAttribute("cx", cx);
                    circle.setAttribute("cy", cy);
                    circle.setAttribute("r", 1);
                    newContactPath.appendChild(circle);
                };

                // JSON does not recognize SVG-Points, so it was required create an object with x and y
                if (obj1.polygonId === polygonIdObj1 && obj2.polygonId === polygonIdObj2) {
                    intersection = contact.intersection.map(point => ( {x: point.x, y: point.y} ));
                } else {
                    intersection = this.getIntersection(polygonIdObj1, polygonIdObj2, svgDoc);
                    contact.intersection = intersection.map(point => ( {x: point.x, y: point.y} ));
                }

                intersection.forEach((point) => { addCircle(point.x, point.y) })
                newContactPath.classList.add("contact-path");
                newContactPath.setAttribute("fill", "black");
                parent.appendChild(newContactPath);
                obj1.polygonId = polygonIdObj1;
                obj2.polygonId = polygonIdObj2;
            }
        }
    }

    getAreaOfPolygon(polygonId, scale) {
        const obj = document.querySelector("object");
        const svgDoc = obj?.contentDocument;
        const path = svgDoc?.getElementById(polygonId);
        const vertices = [];
        const { getRealWidth, getRealHeight } = scale;
        let area = 0;

        if (path) {
            for (let i = 0; i < path.getTotalLength(); i++)
                vertices.push(path.getPointAtLength(i));

            for(let i = 0; i < vertices.length; i++) {
                vertices[i].x = getRealWidth(vertices[i].x);
                vertices[i].y = getRealHeight(vertices[i].y);
            }

            for (let i = 0; i < vertices.length; i++) {
                area += vertices[i].x * vertices[(i+1) % vertices.length].y
                      - vertices[i].y * vertices[(i+1) % vertices.length].x;
            }
        }

        return Math.abs(area) * 0.5;
    }

    getArea(polygon) {
        let totalArea = 0;

        polygon.forEach((ring, index) => {
            let ringArea = 0;
            let factor = (index === 0) ? 1 : -1;

            for (let i = 0; i < ring.length - 1; i++)
                ringArea += ring[i][0] * ring[i+1][1]
                          - ring[i][1] * ring[i+1][0];

            totalArea += factor * Math.abs(ringArea) * 0.5;
        })

        return totalArea;
    }

    getLinePolygonIntersections(points, lineStart, lineEnd) {
        const line = turf.lineString([lineStart, lineEnd]);
        const polygon = turf.polygon(points);
        const intersections = turf.lineIntersect(line, polygon).features.map(feature => {
            return feature.geometry.coordinates;
        });
        return intersections;
    }

    transformPoint(x, y, matrix) {
        const { a, b, c, d, e, f } = matrix;
        const newX = a * x + c * y + e;
        const newY = b * x + d * y + f;
        return [newX, newY];
    }

    getPolygon(path, applyTransform=true) {
        const polygon = [];
        if (path === null) {
            return [[]];
        } else if(path.tagName === 'path') {
            const commands = makeAbsolute(parseSVG((path.getAttribute('d'))));
            let ring = [];
            commands.forEach((command) => {
                ring.push([command.x, command.y]);
                if (command.code === 'Z') {
                    polygon.push(ring);
                    ring = [];
                }
            });
        } else {
            const pointStrings = path.getAttribute('points').split(' ');
            const shape = pointStrings.map((pointString, index) => {
                const [x, y] = (index === pointStrings.length - 1)
                    ? pointStrings[0].split(',').map(parseFloat)
                    : pointString.split(',').map(parseFloat);
                return [x, y];
            });
            polygon.push(shape);
        }

        if (applyTransform) {
            const transformMatrix = path.transform.baseVal.length > 0 ?
                path.transform.baseVal[0].matrix :
                { a: 1, b: 0, c: 0, d: 1, e: 0, f: 0 };
            const parentTransformMatrix = path.parentNode.transform.baseVal.length > 0 ?
                path.parentNode.transform.baseVal[0].matrix :
                { a: 1, b: 0, c: 0, d: 1, e: 0, f: 0 };
            polygon.forEach(ring =>
                ring.forEach(point => {
                    [point[0], point[1]] = this.transformPoint(point[0], point[1], transformMatrix);
                    [point[0], point[1]] = this.transformPoint(point[0], point[1], parentTransformMatrix);
                })
            );
        }

        return polygon;
    }

    /*
        TODO: The approximation is not good because of the low number of lines.
        By moving this to worker, increse the number of lines to get a better
        approximation.
    */
    getPolygonProperties(polygonId, scale, polygonBBox, points=null) {
        const obj = document.querySelector("object");
        const svgDoc = obj?.contentDocument;
        const path = svgDoc?.getElementById(polygonId);

        if (!points && !path)
            return {};

        const slack = 1.0;
        const nrLines = 150;
        const minX = polygonBBox.x - slack;
        const minY = polygonBBox.y - slack;
        const maxX = polygonBBox.x + polygonBBox.width + slack;
        const maxY = polygonBBox.y + polygonBBox.height + slack;
        const stepX = (maxX - minX) / nrLines;
        const stepY = (maxY - minY) / nrLines;

        // Require a deep copy because scaling modify the original array.
        const polygon = points ? structuredClone(points) : this.getPolygon(path, false);
        const heights = [];
        const widths = [];

        const scaleDomainX = Number(svgDoc.querySelector("#ESCALA_HORIZONTAL text").textContent.replace(/\D/g, ""));
        const scaleDomainY = Number(svgDoc.querySelector("#ESCALA_VERTICAL text").textContent.replace(/\D/g, ""));
        const scaleRangeX = Number(svgDoc.querySelector("#ESCALA_HORIZONTAL rect").getAttribute("width"));
        const scaleRangeY = Number(svgDoc.querySelector("#ESCALA_VERTICAL rect").getAttribute("height"));
        const getRealWidth = (width) => (scaleDomainX * width) / scaleRangeX;
        const getRealHeight = (height) => (scaleDomainY * height) / scaleRangeY;

        for (let x = minX; x < maxX; x += stepX) {
            let lineStart = [x, minY];
            let lineEnd = [x, maxY];
            let intersections = this.getLinePolygonIntersections(polygon, lineStart, lineEnd);
            intersections.sort((a, b) => a[1] - b[1]);
            for(let i = 0; i < intersections.length - 1; i += 2)
                heights.push(getRealHeight(Math.abs(intersections[i][1] - intersections[i+1][1])));
        }

        for (let y = minY; y < maxY; y += stepY) {
            let lineStart = [minX, y];
            let lineEnd = [maxX, y];
            let intersections = this.getLinePolygonIntersections(polygon, lineStart, lineEnd);
            intersections.sort((a, b) => a[0] - b[0]);
            // console.log(intersections);
            for(let i = 0; i < intersections.length - 1; i += 2)
                widths.push(getRealWidth(Math.abs(intersections[i][0] - intersections[i+1][0])));
        }

        return {
            area: this.getArea(polygon),
            heightMean: mean(heights),
            heightMedian: median(heights),
            heightMode: mode(heights),
            heightMax: max(heights),
            heightMin: min(heights),
            widthMean: mean(widths),
            widthMedian: median(widths),
            widthMode: mode(widths),
            widthMax: max(widths),
            widthMin: min(widths),
            width: getRealWidth(polygonBBox.width),
            thickness: getRealHeight(polygonBBox.height)
        }
    }

    firstSelect(elem) {
        elem.classList.add(this.svgClass);
    }

    getPolygons(layers, scale) {
        const { UNIT, ARCH_ELEMENT, FACIES_ASSOCIATION } = ObjectTypes;
        const geologicalObjectType = {
            'INTERUNIDADE': UNIT,
            'COMPLEXO': ARCH_ELEMENT,
            'COMPOSTO': ARCH_ELEMENT,
            'INDIVIDUO': ARCH_ELEMENT,
            'ASSOCIACAO_DE_FACIES': FACIES_ASSOCIATION,
            // 'ASSOCIA____O_DE_F__CIES': FACIES_ASSOCIATION,
        };
        const getIcon = (type) => {
            switch (type) {
                case FACIES_ASSOCIATION:
                    return <tbIcons.TbChartRadar />;
                case UNIT:
                    return <FaIcons.FaRegCircle />;
                case ARCH_ELEMENT:
                    return <tbIcons.TbChartBubble />;
                default:
                    return;
            }
        }

        const polygons = layers.map((layer) => {
            const childrens = Array.prototype.slice.call(layer.children);
            // console.log(layer.id);
            if (layer.id in geologicalObjectType)
                return childrens.map(child => {
                    const points = this.getPolygon(child, false);

                    const obj =  {
                        title: child.id,
                        key: child.id,
                        label: child.id,
                        value: child.id,
                        idNumber: 0,
                        //geologicalObjectType: geologicalObjectType,
                        properties: { polygonId: child.id, ...(this.getPolygonProperties(child.id, scale, child.getBBox(), points)) },
                        points: points,
                        bbox: child.getBBox(),
                        geologicalObjectType: geologicalObjectType[layer.id],
                        icon: getIcon(geologicalObjectType[layer.id]),
                    }

                    return obj
                });
            else
                return [];
        });

        return polygons.flat();
    }

    /*
        TODO: this function compares each innerPoint to the each outerPoint
        and check if the distance is less than a threshold. Change that to
        compare the distance of each innerPoint to each LINE of the outerPolygon.
    */
    contains(outerPolygon, innerPolygon) {
        const Squaredthreshold = 0.5;
        const innerPolygonsPoints = innerPolygon.points[0];
        const squaredDistance = (point1, point2) => (
            (point1[0] - point2[0])**2 + (point1[1] - point2[1])**2
        );

        const ringContainsInnerPolygon = (index) => {
            const outerPolygonTurf = turf.polygon([outerPolygon.points[index]]);
            const outerPolygonsPoints = outerPolygon.points[index];
            for(const innerPoint of innerPolygonsPoints) {
                if (!turf.booleanPointInPolygon(turf.point(innerPoint), outerPolygonTurf)) {
                    let withinThreshold = false;
                    for (const outerPoint of outerPolygonsPoints) {
                        if (squaredDistance(outerPoint, innerPoint) <= Squaredthreshold) {
                            withinThreshold = true;
                            break;
                        }
                    }
                    if (!withinThreshold) {
                        return false;
                    }
                }
            }
            return true;
        }

        // Caso o innerPolygon esteja contido no outerPolygon
        if (ringContainsInnerPolygon(0)) {
            // Caso o innerPolygon esteja contindo em um dos poligonos internos do outerPolygon, então retornar false.
            for (let i = 1; i < outerPolygon.points.length; i++)
                if (ringContainsInnerPolygon(i))
                    return false;
            return true;
        } else {
            return false;
        }
    }

    findParentNode(polygon, nodes) {
        const { FACIES_ASSOCIATION } = ObjectTypes;
        for (const node of nodes) {
            if (node.geologicalObjectType !== FACIES_ASSOCIATION && this.contains(node, polygon)) {
                const result = this.findParentNode(polygon, node.children);
                return (result !== null) ? result : node;
            }
        }
        return null;
    }

    findChildrenNodes(polygon, nodes) {
        const { FACIES_ASSOCIATION } = ObjectTypes;
        const children = [];
        for (const node of nodes) {
            if (polygon.geologicalObjectType !== FACIES_ASSOCIATION && this.contains(polygon, node)) {
                children.push(node);
            }
        }
        return children;
    }

    buildTree(polygons) {
        const recursiveRemovePoints = (node) => {
            delete node.points;
            if(node.children)
                node.children.forEach(child => recursiveRemovePoints(child));
        }

        let roots = [];
        for (const polygon of polygons) {
            let parent = this.findParentNode(polygon, roots);
            let nodes = (parent === null) ? roots : parent.children;
            let children = this.findChildrenNodes(polygon, nodes);
            nodes.splice(0, nodes.length, ...nodes.filter(parentChild =>
                !children.some(child => parentChild.key === child.keys)
            ));
            nodes.unshift({ ...polygon, children : children });
        }

        // Romove points to not exceed local session max size (5 MB)
        roots.forEach(node => recursiveRemovePoints(node));

        return roots
    }

    hexToRgb(hex) {
        const bigint = parseInt(hex, 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;
        return [r, g, b];
    }

    rgbDifference(color1, color2) {
        return Math.sqrt(
            color1.reduce((acc, val, index) => acc + Math.pow(val - color2[index], 2), 0)
        );
    }

    closestColor(targetColorHex, colorListHex) {
        const targetColor = this.hexToRgb(targetColorHex);
        const colorList = colorListHex.map(this.hexToRgb);
        let minDifference = Infinity;
        let closestColor = null;

        colorList.forEach((color, index) => {
            const difference = this.rgbDifference(targetColor, color);
            if (difference < minDifference) {
                minDifference = difference;
                closestColor = colorListHex[index];
            }
        });

        return closestColor;
    }

    getSuperBbox = (bboxes) => {
        let minX = Number.MAX_VALUE,
            minY = Number.MAX_VALUE,
            maxX = Number.MIN_VALUE,
            maxY = Number.MIN_VALUE;

        bboxes.forEach(bbox => {
            minX = Math.min(minX, bbox[0]);
            minY = Math.min(minY, bbox[1]);
            maxX = Math.max(maxX, bbox[2]);
            maxY = Math.max(maxY, bbox[3]);
        })

        return {
            minX: minX,
            minY: minY,
            maxX: maxX,
            maxY: maxY,
        }
    }

    rgbToHex(rgb) {
        const result = rgb.match(/\d+/g);
        if (!result || result.length !== 3)
            return null;

        const hex = result.map(num => {
            const hexPart = parseInt(num).toString(16);
            return hexPart.length === 1 ? '0' + hexPart : hexPart;
        }).join('');

        return hex;
    }

    getFillColor(object) {
        let color = object.style.fill;

        if (color && color.startsWith('rgb'))
            color = this.rgbToHex(color);
        if (!color || color === 'none')
            color = object.getAttribute('fill');
        if (color)
            color = color.replace("#", "");

        return color;
    }

    getProportionInBox(tree, bbox, step) {
        // console.log(tree, bbox, step);
        const doc = document.querySelector("object");
        const svgDoc = doc?.contentDocument;

        if (!svgDoc)
            return;

        // const newContactPath = document.createElementNS(
        //     "http://www.w3.org/2000/svg",
        //     "g"
        // );

        // const addLine = (x1, y1, x2, y2) => {
        //     const line = document.createElementNS(
        //         "http://www.w3.org/2000/svg",
        //         "line"
        //     );
        //     line.setAttribute("x1", x1);
        //     line.setAttribute("y1", y1);
        //     line.setAttribute("x2", x2);
        //     line.setAttribute("y2", y2);
        //     line.setAttribute('stroke', 'black');
        //     line.setAttribute('stroke-width', '0.1');
        //     newContactPath.appendChild(line);
        // };

        const scaleDomainX = Number(svgDoc.querySelector("#ESCALA_HORIZONTAL text").textContent.replace(/\D/g, ""));
        const scaleDomainY = Number(svgDoc.querySelector("#ESCALA_VERTICAL text").textContent.replace(/\D/g, ""));
        const scaleRangeX = Number(svgDoc.querySelector("#ESCALA_HORIZONTAL rect").getAttribute("width"));
        const scaleRangeY = Number(svgDoc.querySelector("#ESCALA_VERTICAL rect").getAttribute("height"));
        const getRealWidth = (width) => (scaleDomainX * width) / scaleRangeX;
        const getRealHeight = (height) => (scaleDomainY * height) / scaleRangeY;

        const series = [];
        const colorMap = {};
        const polygons = [];

        const legendGroup = svgDoc.getElementById('LEGENDA');
        legendGroup.querySelectorAll('g').forEach(group => {
            const color = group.querySelector('rect').getAttribute('fill').replace("#", "");
            const text = group.querySelector('text').textContent;
            const [r,g,b] = this.hexToRgb(color);
            const hue = tinycolor({ r: r, g: g, b: b }).toHsl().h;
            series.push({
                name: text,
                data: [],
                color: '#' + color,
                hue: hue,
                showInLegend: false,
                __color: color
            });
            colorMap[color] = text;
        });

        series.sort((a, b) => b.hue - a.hue);

        const buildPolygons = (tree) => {
            tree.forEach(node => {
                node.properties.polygonId.forEach(polygonId => {
                    const path = svgDoc.getElementById(polygonId);
                    if (path !== null) {
                        const points = this.getPolygon(path);
                        const color = this.getFillColor(path);
                        if (color !== 'none' && !polygons.some(polygon => polygon.id === polygonId)) {
                            polygons.push({
                                id: polygonId,
                                points: points,
                                color: color in colorMap ? color : this.closestColor(color, Object.keys(colorMap)),
                                bbox: turf.bbox(turf.polygon(points))
                            })
                        }
                    }
                });
                if (node.children && node.children.length > 0)
                    buildPolygons(node.children);
            })
        }

        buildPolygons(tree);
        const superBBox = this.getSuperBbox(polygons.map(polygon => polygon.bbox));

        const lineIntersect = (lineStart, lineEnd, result) => {
            polygons.forEach((polygon) => {
                const { points, color, bbox } = polygon;
                const [x1, y] = lineStart;
                const x2 = lineEnd[0];
                const [minX, minY, maxX, maxY] = bbox;

                const isYOutsideBounds = y < minY || y > maxY;
                const isXOutsideBounds = (x1 < minX && x2 < minX) || (x1 > maxX && x2 > maxX);
                if (isYOutsideBounds || isXOutsideBounds) {
                    return;
                }

                if (color !== 'none') {
                    const intersections = this.getLinePolygonIntersections(points, lineStart, lineEnd);
                    if (points.some(ring => turf.booleanPointInPolygon(turf.point(lineStart), turf.polygon([ring]))))
                        intersections.push(lineStart);
                    if (points.some(ring => turf.booleanPointInPolygon(turf.point(lineEnd), turf.polygon([ring]))))
                        intersections.push(lineEnd);
                    intersections.sort((a, b) => a[0] - b[0]);
                    for(let j = 0; j < intersections.length - 1; j += 2) {
                        result[color] += getRealWidth(Math.abs(intersections[j][0] - intersections[j+1][0]));
                        // addLine(intersections[j][0], intersections[j][1], intersections[j+1][0], intersections[j+1][1]);
                    }
                }
            });
        };

        const slack = 0.05;
        const minX = Math.max(bbox.x, superBBox.minX) - slack;
        const minY = Math.max(bbox.y, superBBox.minY) + slack;
        const maxX = Math.min(bbox.x + bbox.width, superBBox.maxX) + slack;
        const maxY = Math.min(bbox.y + bbox.height, superBBox.maxY) - slack;
        const realStep = (step * scaleRangeY / scaleDomainY);

        for (let y = minY; y <= maxY; y += realStep) {
            const lineStart = [minX, y];
            const lineEnd = [maxX, y];
            const result = series.reduce((x, {__color}) => (x[__color] = 0, x), {});
            lineIntersect(lineStart, lineEnd, result);
            series.forEach(serie => {
                serie.data.push([getRealHeight(y - minY), result[serie.__color]]);
                if (result[serie.__color] > 0) {
                    serie.showInLegend = true;
                }
            });
        }

        // newContactPath.classList.add("contact-path");
        // newContactPath.setAttribute("fill", "black");
        // svgDoc.children[0].children[1].appendChild(newContactPath);

        return series;
    }

    getPit(tree, x) {
        const doc = document.querySelector("object");
        const svgDoc = doc?.contentDocument;

        if (!svgDoc)
            return;

        const scaleDomainX = Number(svgDoc.querySelector("#ESCALA_HORIZONTAL text").textContent.replace(/\D/g, ""));
        const scaleDomainY = Number(svgDoc.querySelector("#ESCALA_VERTICAL text").textContent.replace(/\D/g, ""));
        const scaleRangeX = Number(svgDoc.querySelector("#ESCALA_HORIZONTAL rect").getAttribute("width"));
        const scaleRangeY = Number(svgDoc.querySelector("#ESCALA_VERTICAL rect").getAttribute("height"));
        const getRealWidth = (width) => (scaleDomainX * width) / scaleRangeX;
        const getRealHeight = (height) => (scaleDomainY * height) / scaleRangeY;

        const series = [];
        const colorMap = {};
        const polygons = [];

        const legendGroup = svgDoc.getElementById('LEGENDA');
        legendGroup.querySelectorAll('g').forEach(group => {
            const color = group.querySelector('rect').getAttribute('fill').replace("#", "");
            const text = group.querySelector('text').textContent;
            colorMap[color] = text;
        });

        const buildPolygons = (tree) => {
            tree.forEach(node => {
                const hasChildren = node.hasOwnProperty('children') && node.children.length > 0;
                node.properties.polygonId.forEach(polygonId => {
                    const path = svgDoc.getElementById(polygonId);
                    // window.getComputedStyle(path.parentNode).getPropertyValue('display') != 'none'
                    if (path !== null) {
                        const points = this.getPolygon(path);
                        const color = this.getFillColor(path);
                        if (color !== 'none' && !polygons.some(polygon => polygon.id === polygonId)) {
                            polygons.push({
                                id: polygonId,
                                name: node.title,
                                points: points,
                                color: color in colorMap ? color : this.closestColor(color, Object.keys(colorMap)),
                                bbox: turf.bbox(turf.polygon(points)),
                            })
                        }
                    }
                });
                if (hasChildren)
                    buildPolygons(node.children);
            })
        }

        buildPolygons(tree);
        const superBBox = this.getSuperBbox(polygons.map(polygon => polygon.bbox));

        const lineIntersect = (lineStart, lineEnd) => {
            polygons.forEach((polygon) => {
                const { id, points, color, bbox, name } = polygon;
                const [x, y1] = lineStart;
                const y2 = lineEnd[1];
                const [minX, minY, maxX, maxY] = bbox;

                const isXOutsideBounds = x < minX || x > maxX;
                const isYOutsideBounds = (y1 < minY && y2 < minY) || (y1 > maxY && y2 > maxY);
                if (isXOutsideBounds || isYOutsideBounds) {
                    return;
                }

                const intersections = this.getLinePolygonIntersections(points, lineStart, lineEnd);
                if (color !== 'none' && intersections.length > 1) {
                    intersections.sort((a, b) => a[1] - b[1]);
                    const data = [];
                    for(let i = 0; i < intersections.length - 1; i += 2)
                        data.push({ high: intersections[i+1][1], low: intersections[i][1], x: x })

                    const existingSeries = series.find(s => s.name === name);
                    if (existingSeries)
                        existingSeries.data.push(...data);
                    else
                        series.push({
                            name: name,
                            data: data,
                            color: '#' + color,
                            firstIntersection: intersections[0][1],
                        })
                }
            });
        };

        const lineStart = [x, superBBox.minY - 1.0];
        const lineEnd = [x, superBBox.maxY + 1.0];
        let highest = -Infinity;
        lineIntersect(lineStart, lineEnd);
        series.sort((a,b) => a.firstIntersection - b.firstIntersection);
        if (series.length > 0) {
            const min = series[0].firstIntersection;
            series.forEach(serie => {
                serie.data.forEach(data => {
                    data.high = getRealHeight(data.high - min);
                    data.low = getRealHeight(data.low - min);
                    highest = Math.max(highest, data.high);
                })
            })
        }
        // console.log(series);
        return { series: series, highest: highest };
    }
}
