import { useContext, useState, useRef } from "react";
import { store } from '../../../../Store';
import { setSelectingContact } from '../../../../Store/actions';
import ObjectsTree from "./ObjectsTree";
import { useNavigate } from "react-router-dom";
import { SmallDashOutlined, SearchOutlined, DeleteOutlined, PlusOutlined, ApartmentOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Card, Button, Tooltip, message } from 'antd';
import ContactsForm from "../ContactsForm";
import AddObjectModal from "./addObjectModal";
import "./index.css"

/*
    This function has two returns: [areSiblings, parent]
    if they are not siblings, areSibling is false and parent null
    if they are siblings but both are root, areSibling is true and parent null
    if they are siblings and not root, the areSibling is true and parent is the parent key
*/
function siblings(data, keys, parent=null) {
    if (!data || data.length === 0) return [false, null];
    const childs = data.map(child => child.key);
    const hasAllNodes = keys.every(key => childs.includes(key));

    if (hasAllNodes)
        return [true, parent];

    for (const node of data) {
        const [areSiblings, parentKey] = siblings(node.children, keys, node.key);
        if (areSiblings)
            return [true, parentKey];
    }

    return [false, null];
}

const findObj = (data, key) => {
    let result = null;
    const findData = (data, key) => {
        for (var i = 0; i < data.length; i++) {
            if (data[i].children)
                findData(data[i].children, key);
            if (data[i].key === key) {
                result = data[i];
            }
        }

        return result;
    }

    return findData(data, key);
}

function GeoObjectForm(props) {
    const globalState = useContext(store);
    const { state, dispatch } = globalState;
    const { viewOnly } = props
    const lineH = useRef(null);
    const [height, setHeight] = useState((window.innerHeight - 91) * 0.5);
    const [openContactsSearch, setOpenContactsSearch] = useState(false)
    const [openSearchBar, setOpenSearchBar] = useState(false);
    const [deletingObjects, setDeletingObjects] = useState(false);
    const [deletingContacts, setDeletingContacts] = useState(false);
    const [open, setOpen] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    const parentRef = useRef(null);
    const mergeRef = useRef(null);

    const navigate = useNavigate();

    //If dont have a description
    if (!localStorage.descriptionInformation)
        navigate("./cdescription");

    const onContactTypeClick = ({ key }) => {
        dispatch(setSelectingContact(key));
    };

    const contactMenu = (
        <Menu
            onClick={onContactTypeClick}
            items={[
                { key: "Abrupto", label: "Abrupto" },
                { key: "Críptico", label: "Críptico" },
                { key: "De falha", label: "De falha" },
                { key: "Erosional", label: "Erosional" },
                { key: "Gradacional", label: "Gradacional" },
                { key: "Indefinido", label: "Indefinido" },
                { key: "Linha de seixos", label: "Linha de seixos" },
                { key: "Amalgamado", label: "Amalgamado" },
                { key: "Concordante", label: "Concordante" }
            ]}
        />
    );

    const handleMouseDown = (e) => {
        const lineStyle = lineH.current.style;
        const objectsCard = document.getElementById("objectsCard");
        const contactsCard = document.getElementById("contactsCard");
        const top = Math.floor(objectsCard.getBoundingClientRect().top);
        const objectsHeadHeight = objectsCard.children[0].getBoundingClientRect().height + 3;
        const contactsHeadHeight = objectsCard.children[0].getBoundingClientRect().height + 3;
        lineStyle.visibility = 'visible';
        lineStyle.top = (e.clientY >= window.innerHeight - contactsHeadHeight)
            ? lineStyle.top = window.innerHeight - contactsHeadHeight
            : (e.clientY - top) + 'px';

        const handleMouseMove = (e) => {
            console.log(e.clientY)
            if (e.clientY <= top + objectsHeadHeight)
                lineStyle.top = objectsHeadHeight + 'px';
            else if (e.clientY >= window.innerHeight - contactsHeadHeight)
                lineStyle.top = window.innerHeight - contactsHeadHeight;
            else
                lineStyle.top = (e.clientY - top) + 'px';
        }

        const handleMouseUp = (e) => {
            if (e.clientY <= top + objectsHeadHeight)
                setHeight(0)
            else if (e.clientY >= window.innerHeight - contactsHeadHeight)
                setHeight(window.innerHeight - top - 87)
            else
                setHeight(e.clientY - 41 - top)
            lineStyle.visibility = 'hidden';
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };

        e.preventDefault();
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    };

    const mergeObjects = () => {
        const objects = state.descriptionObjects.objects;
        const selecteds = state.descriptionObjects.selectedObject.map(key => findObj(objects, key));
        const [areSiblings, parent] = siblings(objects, state.descriptionObjects.selectedObject);
        const allSameGeologicalObject = selecteds.every((obj, _, arr) =>
            obj.geologicalObjectType === arr[0].geologicalObjectType
        );
        const messageError = (error) => messageApi.open({
            type: 'error',
            content: error,
        });

        if (!allSameGeologicalObject) {
            messageError('Objetos devem possuir o mesmo tipo geológico.');
            return;
        }
        if (!areSiblings) {
            messageError('Objetos devem pertencer a mesma escala.');
            return;
        }

        parentRef.current = parent;
        mergeRef.current = state.descriptionObjects.selectedObject;
        setOpen(true);
    }

    const menuObjects = [
        <Tooltip title="Adicionar">
            <Button type="link" size="small"
                icon={<PlusOutlined className={`icon ${open ? 'glow' : ''}`} style={{ display: "inline-flex" }} />}
                onClick={() => { parentRef.current = null; mergeRef.current = null; setOpen(true); }}
            />
        </Tooltip>,

        <Tooltip title="Pesquisar">
            <Button type="link" size="small"
                icon={<SearchOutlined className={`icon ${openSearchBar ? 'glow' : ''}`} style={{ display: "inline-flex" }} />}
                onClick={() => { setOpenSearchBar(!openSearchBar) }}
            />
        </Tooltip>,

        <Tooltip title="Remover">
            <Button type="link" size="small"
                icon={<DeleteOutlined className={`icon ${deletingObjects ? 'glow' : ''}`} style={{ display: "inline-flex" }} />}
                onClick={() => { setDeletingObjects(!deletingObjects) }}
                disabled={(state.descriptionObjects.contacts.selectingContact !== null)}
            />
        </Tooltip>,

        <Tooltip title="Unir">
            <Button
                type="link"
                size="small"
                icon={<ApartmentOutlined style={{ display: "inline-flex" }} rotate={90} />}
                disabled={state.descriptionObjects.selectedObject.length <= 1}
                onClick={mergeObjects}
            />
        </Tooltip>
    ]

    const menuContacts = [
        <Tooltip title="Criar Contato">
            <Dropdown overlay={contactMenu} trigger={['click']} disabled={deletingObjects}>
                <Button type="link" size="small"
                    icon={<PlusOutlined style={{ display: "inline-flex" }} />}
                    onClick={(e) => e.preventDefault()}>
                </Button>
            </Dropdown>
        </Tooltip>,

        <Tooltip title="Pesquisar">
            <Button type="link" size="small"
                icon={<SearchOutlined className={`icon ${openContactsSearch ? 'glow' : ''}`} style={{ display: "inline-flex" }} />}
                onClick={() => { setOpenContactsSearch(!openContactsSearch) }}
            />
        </Tooltip>,

        <Tooltip title="Remover">
            <Button type="link" size="small"
                icon={<DeleteOutlined className={`icon ${deletingContacts ? 'glow' : ''}`} style={{ display: "inline-flex" }} />}
                onClick={() => { setDeletingContacts(!deletingContacts) }}
            />
        </Tooltip>,
    ]

    return (
        <>
            {contextHolder}
            <Card
                id="objectsCard"
                title="Objetos Geológicos"
                extra={viewOnly ? [] : menuObjects}
                size="small"
                bodyStyle={{
                    height: height,
                    padding: "1px",
                }}
            >
                <ObjectsTree viewOnly={viewOnly} height={height} openSearchBar={openSearchBar} deletingObjects={deletingObjects} />
            </Card>

            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    cursor: "row-resize",
                    backgroundColor: '#DCDCDC'
                }}
                onMouseDown={handleMouseDown}
            >
                <SmallDashOutlined
                    style={{
                        fontSize: '8px',
                        transform: 'scale(3, 1)',
                    }}
                />
            </div>

            <div id="line-H" ref={lineH}>
                <SmallDashOutlined
                    style={{
                        fontSize: '8px',
                        transform: 'scale(3, 1)',
                    }}
                />
            </div>

            <Card
                id="contactsCard"
                title="Contatos"
                size="small"
                extra={viewOnly ? [] : menuContacts}
                bodyStyle={{
                    overflowY: 'auto',
                    height: (window.innerHeight - 91) - height,
                }}
            >
                <ContactsForm viewOnly={viewOnly} openContactsSearch={openContactsSearch} deletingContacts={deletingContacts} />
            </Card>

            {open && <AddObjectModal setOpen={setOpen} parentRef={parentRef} mergeRef={mergeRef} /> }
        </>
    );
}

export default GeoObjectForm;