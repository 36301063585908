import { useState, useContext, useMemo, useEffect, useRef } from 'react';
import { store } from '../../Store';
import { Map, LocationMarkerOnClick, LocationMarker } from '../../Maps/index';
import InputMultiplePrint from './InputMultiplePrint';
import { useAxiosGet } from '../../../hooks/useAxios';
import { setDescriptionInformationAction } from '../../Store/actions';
import { initialState } from '../../Store/index';
import { TreeSelect } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Radio, Modal, Space, Row, Col, Form, Input, Select, InputNumber, Button, Card, DatePicker, Breadcrumb, Layout, Divider, message, Typography } from 'antd'
import FloatLabel from "../../FloatLabel";
import moment from 'moment';
import "./index.css"
import { PlusOutlined } from '@ant-design/icons';
import InputMask from 'react-input-mask';

const { Option } = Select;
const { TextArea } = Input;
const { Content } = Layout;
const { Link } = Typography;

const latitudePattern = /^(\d+)° (\d+)' (\d+\.\d+)" ([NnSs])$/;
const longitudePattern = /^(\d+)° (\d+)' (\d+\.\d+)" ([EeWw])$/;
const formatCharsLatitude = { 'P': '[NnSs]', '9': '[0123456789]' };
const formatCharsLongitude = { 'P': '[EeWw]', '9': '[0123456789]' };

function getDMS(coord, isLatitude) {
    if (coord != null && typeof (coord) == 'string') {
        const match = isLatitude ? coord.match(latitudePattern) : coord.match(longitudePattern);
        if (Array.isArray(match)) {
            return {
                degrees: parseInt(match[1]),
                minutes: parseInt(match[2]),
                seconds: parseFloat(match[3]),
                direction: match[4],
            }
        }
    }
    return {};
}

function DMSToDecimal(coord, isLatitude) {
    const dms = getDMS(coord, isLatitude);
    if (Object.keys(dms).length > 0) {
        const { degrees, minutes, seconds, direction } = dms;
        console.log(degrees, minutes, seconds, direction)
        const signal = ('SsWw'.indexOf(direction) >= 0) ? -1 : 1;
        console.log(signal);
        const decimal = signal * (degrees + (minutes / 60) + (seconds / 3600));
        return decimal;
    } else {
        return null;
    }
}

function padLeft(n, width) {
    const z = '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

function decimalToDMS(coord, isLatitude) {
    if (coord == null) {
        return null;
    }

    const absoluteValue = Math.abs(coord);
    const degrees = Math.trunc(absoluteValue);
    const minutesFloat = (absoluteValue - degrees) * 60;
    const minutes = Math.trunc(minutesFloat);
    const seconds = ((minutesFloat - minutes) * 60).toFixed(4);
    const direction = isLatitude ? (coord > 0 ? 'N' : 'S') : (coord > 0 ? 'E' : 'W');

    return `${padLeft(degrees, 3)}° ${padLeft(minutes, 2)}' ${padLeft(seconds, 7)}" ${direction}`;
}

function CreateDescription(props) {
    const globalState = useContext(store);
    const { state, dispatch } = globalState;
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const { viewOnly } = props;

    //const navigate = useNavigate();
    const descriptorNameExists = localStorage.getItem("user") !== null;
    const descriptorName = descriptorNameExists ? JSON.parse(localStorage.getItem("user")).username : "";
    const dateFormat = "YYYY/MM/DD";
    const initialDescriptionInformation = initialState.descriptionInformation;
    const [latLong, setLatLong] = useState({
        latitude: state.descriptionInformation.latitude,
        longitude: state.descriptionInformation.longitude
    });

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [coordinatesType, setCoordinatesType] = useState(state.descriptionInformation.coordinatesType ?? "dms");

    // Api calls
    const descriptionInfoURL = "http://api-geoserver.inf.ufrgs.br/description/data";
    const urlDescription = 'geologicalContext';
    const urlBasinDescription = 'watershed';
    const urlGeochronologyDescription = 'geochronology';
    const urlSedimentaryEnvironmentDescription = 'sedimentaryEnvironment'
    const urlDepositionalSystem = 'depositionalSystem'

    const { data: descriptionFieldsData, error: descriptionFieldsDataError, loaded: descriptionFieldsDataLoaded } = useAxiosGet(descriptionInfoURL, sessionStorage);

    const descriptionFields = useMemo(() => {
        let parsedDescriptionFields = {};

        const generateList = (data) => {
            let tempList = [];
            for (let i = 0; i < data.length; i++) {
                const node = data[i];
                let newNode = {}
                newNode.title = node.labels.pt[0]
                newNode.value = node.iri;
                if (node.types)
                    newNode.children = generateList(node.types)
                else
                    newNode.children = [];
                tempList.push(newNode);
            }
            return tempList;
        };

        if (descriptionFieldsData) {
            let basinsData = descriptionFieldsData[urlDescription][urlBasinDescription].types;
            parsedDescriptionFields.basins = basinsData;

            let geochronologyData = descriptionFieldsData[urlDescription][urlGeochronologyDescription].types;
            parsedDescriptionFields.geochronology = generateList(geochronologyData);

            let sedimentaryEnvironmentData = descriptionFieldsData[urlDescription][urlSedimentaryEnvironmentDescription].types;
            parsedDescriptionFields.sedimentaryEnvironment = generateList(sedimentaryEnvironmentData);

            let depositionalSystemData = descriptionFieldsData[urlDescription][urlDepositionalSystem].types;
            parsedDescriptionFields.depositionalSystem = generateList(depositionalSystemData);

            return parsedDescriptionFields;
        }
    }, [descriptionFieldsData]);

    useEffect(() => {
        const { latitude, longitude } = form.getFieldsValue();
        let newLatitude, newLongitude;
        if (coordinatesType === 'dd') {
            newLatitude = (latitude != null) ? DMSToDecimal(latitude, true) : null;
            newLongitude = (longitude != null) ? DMSToDecimal(longitude, false) : null;
        } else {
            newLatitude = (latitude != null) ? decimalToDMS(latitude, true) : null;
            newLongitude = (longitude != null) ? decimalToDMS(longitude, false) : null;
        }
        form.setFieldsValue({ latitude: newLatitude, longitude: newLongitude });
    }, [coordinatesType])

    useEffect(() => {
        const { latitude, longitude } = latLong;
        let { latitude: newLatitude, longitude: newLongitude } = form.getFieldsValue();
        if (latitude != null)
            newLatitude = (coordinatesType === 'dd') ? latitude : decimalToDMS(latitude, true);
        if (longitude != null)
            newLongitude = (coordinatesType === 'dd') ? longitude : decimalToDMS(longitude, false);
        form.setFieldsValue({ latitude: newLatitude, longitude: newLongitude });
    }, [latLong])

    const descriptorasAuthor = () => {
        form.setFieldsValue({ author: descriptorName });
    }

    //Save input values
    const handleOnSaveADescription = (values) => {
        values.coordinatesType = coordinatesType;
        values.latitude = latLong.latitude;
        values.longitude = latLong.longitude;
        dispatch(setDescriptionInformationAction(values));
        navigate('/objdescription');
    };

    // TODO: refactor the api call here.
    const getAddress = (latLong) => {
        var request = new XMLHttpRequest();
        if (!latLong)
            return;

        const { latitude, longitude } = latLong;

        if (latitude !== null && longitude !== null) {
            var method = 'GET';
            var url = 'https://nominatim.openstreetmap.org/reverse?format=json&lat=' + latitude + '&lon=' + longitude;
            var async = true;

            request.open(method, url, async);
            request.onreadystatechange = function () {
                if (request.readyState === 4 && request.status === 200) {
                    var data = JSON.parse(request.responseText);
                    if (data.address !== undefined) {
                        let countryName = data.address.country || "";
                        let stateName = data.address.state || "";
                        let cityName = data.address.city || "";
                        form.setFieldsValue({ country: countryName, state: stateName, city: cityName });
                    }
                    else
                        form.setFieldsValue({ country: "", state: "", city: "" })
                }
            };
            request.send();
        } else {
            form.setFieldsValue({ country: "", state: "", city: "" })
        }
    };

    const getMapAdress = () => {
        let { latitude, longitude } = form.getFieldsValue();
        let newLatitude, newLongitude;

        if (coordinatesType === 'dd') {
            newLatitude = (latitude != null) ? latitude : null;
            newLongitude = (longitude != null) ? longitude : null;
        } else {
            newLatitude = (latitude != null) ? DMSToDecimal(latitude, true) : null;
            newLongitude = (longitude != null) ? DMSToDecimal(longitude, false) : null;
        }

        setLatLong(prevState => ({
            ...prevState,
            latitude: newLatitude,
            longitude: newLongitude
        }))
    }

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const contentStyle = viewOnly ? {} : { padding: '0px 30px 30px 30px' };

    function sortObjectList(list) {
        list.sort(function (a, b) {
            return a.title.localeCompare(b.title);
        });

        list.forEach(function (object) {
            if (object.children && object.children.length > 0) {
                object.children = sortObjectList(object.children);
            }
        });

        return list;
    }

    const onFinishFailed = (errorInfo) => {
        errorInfo.preventDefault();
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevent form submission on Enter key press
        }
    };

    return (
        <>
            <Content style={contentStyle}>
                <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                    {!viewOnly && !state.isEditing &&
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">
                                <Link>Home </Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                Cadastro de Análogos
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    }

                    {!viewOnly && state.isEditing &&
                        <Breadcrumb>
                            <Breadcrumb.Item href="/">
                                <Link>Home </Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item href="description">
                                <Link>Busca de Análogos</Link>
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>
                                Editar Análogo
                            </Breadcrumb.Item>
                        </Breadcrumb>
                    }

                    <Form
                        name="description"
                        form={form}
                        initialValues={state.descriptionInformation}
                        layout="vertical"
                        onFinish={handleOnSaveADescription}
                        onKeyPress={handleKeyPress}
                    >
                        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                            <Row gutter={16} type="flex">
                                <Col span={12}>
                                    <Card title="Identificação" style={{ height: '100%' }}>
                                        <Row gutter={16}>
                                            <Col span={24}>
                                                <FloatLabel label="Tipo" name="descriptionType" rules={[]}>
                                                    <Select style={{ width: '100%' }} disabled={viewOnly}>
                                                        <Option value="Afloramento">Afloramento</Option>
                                                        <Option value="Subsuperfície">Subsuperfície</Option>
                                                        <Option value="Sistema Recente">Sistema Recente</Option>
                                                        <Option value="Modelo">Modelo</Option>
                                                    </Select>
                                                </FloatLabel>
                                            </Col>
                                        </Row>

                                        <Form.Item
                                            noStyle
                                            shouldUpdate={(prevValues, currentValues) => prevValues.descriptionType !== currentValues.descriptionType}
                                        >
                                            {({ getFieldValue }) =>
                                                getFieldValue("descriptionType") === "Subsuperfície" && (
                                                    <Row gutter={16}>
                                                        <Col span={8}>
                                                            <FloatLabel label="Base" name="bottomDepth" rules={[]}>
                                                                <InputNumber style={{ width: '100%' }} disabled={viewOnly} />
                                                            </FloatLabel>
                                                        </Col>
                                                        <Col span={8}>
                                                            <FloatLabel label="Topo" name="topDepth" rules={[]}>
                                                                <InputNumber style={{ width: '100%' }} disabled={viewOnly} />
                                                            </FloatLabel>
                                                        </Col>
                                                        <Col span={8}>
                                                            <FloatLabel label="Unidade" name="unit" rules={[]}>
                                                                <Select style={{ width: '100%' }} disabled={viewOnly}>
                                                                    <Option value="Metros">Metros</Option>
                                                                    <Option value="Pés">Pés</Option>
                                                                </Select>
                                                            </FloatLabel>
                                                        </Col>
                                                    </Row>
                                                )
                                            }
                                        </Form.Item>

                                        <Form.Item>
                                            <Radio.Group
                                                value={coordinatesType}
                                                onChange={(e) => setCoordinatesType(e.target.value)}
                                                optionType="button"
                                                buttonStyle="solid"
                                            >
                                                <Radio value={'dms'}>Graus° Minutos' Segundos"</Radio>
                                                <Radio value={'dd'}>Decimal</Radio>
                                            </Radio.Group>
                                        </Form.Item>

                                        <Row gutter={16}>
                                            {coordinatesType === 'dd' ?
                                                <>
                                                    <Col span={8}>
                                                        <FloatLabel label="Latitude" name="latitude">
                                                            <InputNumber style={{ width: '100%' }} precision={4} disabled={viewOnly} />
                                                        </FloatLabel>
                                                    </Col>
                                                    <Col span={8}>
                                                        <FloatLabel label="Longitude" name="longitude">
                                                            <InputNumber style={{ width: '100%' }} precision={4} disabled={viewOnly} />
                                                        </FloatLabel>
                                                    </Col>
                                                </>
                                                :
                                                <>
                                                    <Col span={8}>
                                                        <FloatLabel label="Latitude" name="latitude">
                                                            <InputMask
                                                                style={{ width: '100%' }}
                                                                mask={"999° 99' 99.9999\" P"}
                                                                formatChars={formatCharsLatitude}
                                                                disabled={viewOnly}
                                                            >
                                                                {(inputProps) => (
                                                                    <Input
                                                                        {...inputProps} // Propagate all properties to the Input
                                                                    />
                                                                )}
                                                            </InputMask>
                                                        </FloatLabel>

                                                    </Col>
                                                    <Col span={8}>
                                                        <FloatLabel label="Longitude" name="longitude">
                                                            <InputMask
                                                                style={{ width: '100%' }}
                                                                mask={"999° 99' 99.9999\" P"}
                                                                formatChars={formatCharsLongitude}
                                                                disabled={viewOnly}
                                                            >
                                                                {(inputProps) => (
                                                                    <Input
                                                                        {...inputProps} // Propagate all properties to the Input
                                                                    />
                                                                )}
                                                            </InputMask>
                                                        </FloatLabel>
                                                    </Col>
                                                </>
                                            }
                                            <Col span={8}>
                                                {!viewOnly && <Button type="primary" onClick={getMapAdress}>Busca</Button>}
                                            </Col>
                                        </Row>

                                        <Row gutter={16}>
                                            <Col span={8}>
                                                <FloatLabel label="País" name="country">
                                                    <Input style={{ width: '100%' }} disabled={viewOnly} />
                                                </FloatLabel>
                                            </Col>
                                            <Col span={8}>
                                                <FloatLabel label="Estado" name="state">
                                                    <Input style={{ width: '100%' }} disabled={viewOnly} />
                                                </FloatLabel>
                                            </Col>
                                            <Col span={8}>
                                                <FloatLabel label="Cidade" name="city">
                                                    <Input style={{ width: '100%' }} disabled={viewOnly} />
                                                </FloatLabel>
                                            </Col>
                                        </Row>

                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <FloatLabel label="Autor" name="author" rules={[]} required>
                                                    <Input style={{ width: '100%' }} disabled={viewOnly} />
                                                </FloatLabel>
                                            </Col>
                                            <Col span={12}>
                                                {!viewOnly && <Button type="link" onClick={descriptorasAuthor}>Sou o autor</Button>}
                                            </Col>
                                        </Row>

                                        <Row gutter={16}>
                                            <Col span={12}>
                                                <FloatLabel
                                                    label="Data de Produção dos Dados"
                                                    name="dataDate"
                                                    rules={[]}
                                                    getValueFromEvent={(onChange) => moment(onChange).format(dateFormat)}
                                                    getValueProps={(i) => (i !== '') ? ({ value: moment(i) }) : ({ value: null })}
                                                >
                                                    <DatePicker format={dateFormat} style={{ width: '100%' }} allowClear={false} placeholder="" disabled={viewOnly} />
                                                </FloatLabel>
                                            </Col>
                                        </Row>

                                        <Divider orientation="left" plain>Projetos</Divider>
                                        <Row gutter={16}>
                                            <Col span={24}>
                                                {!viewOnly &&
                                                    <Form.Item>
                                                        <Button
                                                            block
                                                            type="dashed"
                                                            onClick={showModal}
                                                            disabled={viewOnly}
                                                        >
                                                            <PlusOutlined style={{ display: 'inline-flex' }} /> Projeto
                                                        </Button>
                                                    </Form.Item>
                                                }
                                            </Col>
                                            <Modal
                                                title="Procurar por Projeto"
                                                visible={isModalOpen}
                                                onOk={handleOk}
                                                onCancel={handleCancel}
                                                style={{ top: 20 }}
                                            >
                                                <p>Placeholder</p>
                                            </Modal>
                                        </Row>

                                        <Divider orientation="left" plain>Documento Fonte / Relatórios</Divider>
                                        <Row gutter={16}>
                                            <Col span={24}>
                                                <InputMultiplePrint title="Documento Fonte / Relatórios" id="docSrc" viewOnly={viewOnly} />
                                            </Col>
                                        </Row>

                                        <Divider orientation="left" plain>Referências / Links</Divider>
                                        <Row gutter={16}>
                                            <Col span={24}>
                                                <InputMultiplePrint title="Referências / Links" id="refLink" viewOnly={viewOnly} />
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>

                                <Col span={12}>
                                    <Card style={{ height: '100%' }}>
                                        {(viewOnly)
                                            ?
                                            <Map center={[state.descriptionInformation.latitude ?? 51.505, state.descriptionInformation.longitude ?? -0.09]} zoom="3" styleHeight='70vh'>
                                                <LocationMarker position={[state.descriptionInformation.latitude, state.descriptionInformation.longitude]} />
                                            </Map>
                                            :
                                            <Map center={[51.505, -0.09]} styleHeight="70vh" zoom="3">
                                                <LocationMarkerOnClick latLong={latLong} setLatLong={setLatLong} getAddress={getAddress} />
                                            </Map>
                                        }
                                    </Card>
                                </Col>
                            </Row>

                            <Card title="Contexto geológico">
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <FloatLabel label="Sítio geológico" name="geologicalSiteName" rules={[]}>
                                            <Input style={{ width: '100%' }} disabled={viewOnly} />
                                        </FloatLabel>
                                    </Col>
                                </Row>

                                <Row gutter={16}>
                                    <Col span={12}>
                                        <FloatLabel label="Bacia" name="watershed" rules={[]}>
                                            <Select
                                                style={{ width: '100%' }}
                                                showSearch
                                                filterOption={(input, option) =>
                                                    option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                disabled={viewOnly}
                                            >
                                                {!descriptionFieldsDataLoaded ? (
                                                    <Option disabled key="loading" value="loading">Carregando...</Option>
                                                ) : (
                                                    descriptionFields.basins.map(basin => (
                                                        <Option key={basin.iri} value={basin.iri} title={basin.labels.pt[0]}>
                                                            {basin.labels.pt[0]}
                                                        </Option>
                                                    ))
                                                )}
                                            </Select>
                                        </FloatLabel>
                                    </Col>
                                    <Col span={12}>
                                        <FloatLabel label="Nome do Campo" name="fieldName" rules={[]}>
                                            <Input style={{ width: '100%' }} disabled={viewOnly} />
                                        </FloatLabel>
                                    </Col>
                                </Row>

                                <Row gutter={16}>
                                    <Col span={12}>
                                        <FloatLabel label="Grupo Litoestratigráfico" name="lithostratigraphyGroup" rules={[]}>
                                            <Input style={{ width: '100%' }} disabled={viewOnly} />
                                        </FloatLabel>
                                        <FloatLabel label="Formação Litoestratigráfica" name="lithostratigraphyFormation" rules={[]}>
                                            <Input style={{ width: '100%' }} disabled={viewOnly} />
                                        </FloatLabel>
                                        <FloatLabel label="Membro Litoestratigráfico" name="lithostratigraphyMember" rules={[]}>
                                            <Input style={{ width: '100%' }} disabled={viewOnly} />
                                        </FloatLabel>
                                    </Col>

                                    <Col span={12}>
                                        <FloatLabel label="Geocronologia" name="geochronology" rules={[]}>
                                            <TreeSelect
                                                style={{ width: '100%' }}
                                                dropdownStyle={{
                                                    maxHeight: 400,
                                                    overflow: 'auto',
                                                }}
                                                treeData={!descriptionFieldsDataLoaded ? (
                                                    [{ value: "Carregando", label: "Carregando...", children: [] }]
                                                ) : (
                                                    descriptionFields.geochronology
                                                )}
                                                multiple
                                                treeLine={{ showLeafIcon: false }}
                                                showArrow
                                                disabled={viewOnly}
                                            />
                                        </FloatLabel>
                                    </Col>
                                </Row>

                                <Row gutter={16}>
                                    <Col span={12}>
                                        <FloatLabel label="Ambiente de Sedimentação" name="sedimentaryEnvironment" rules={[]}>
                                            <TreeSelect
                                                style={{ width: '100%' }}
                                                dropdownStyle={{
                                                    maxHeight: 400,
                                                    overflow: 'auto',
                                                }}
                                                treeData={!descriptionFieldsDataLoaded ? (
                                                    [{ value: "Carregando", label: "Carregando...", children: [] }]
                                                ) : (
                                                    sortObjectList(descriptionFields.sedimentaryEnvironment)
                                                )}
                                                multiple
                                                treeLine={{ showLeafIcon: false }}
                                                showArrow
                                                disabled={viewOnly}
                                            />
                                        </FloatLabel>
                                    </Col>
                                    <Col span={12}>
                                        <FloatLabel label="Sistema Deposicional" name="depositionalSystem" rules={[]}>
                                            <TreeSelect
                                                style={{ width: '100%' }}
                                                dropdownStyle={{
                                                    maxHeight: 400,
                                                    overflow: 'auto',
                                                }}
                                                treeData={!descriptionFieldsDataLoaded ? (
                                                    [{ value: "Carregando", label: "Carregando...", children: [] }]
                                                ) : (
                                                    sortObjectList(descriptionFields.depositionalSystem)
                                                )}
                                                multiple
                                                treeLine={{ showLeafIcon: false }}
                                                showArrow
                                                disabled={viewOnly}
                                            />
                                        </FloatLabel>
                                    </Col>
                                </Row>
                            </Card>

                            <Card title="Informações Adicionais">
                                <FloatLabel label="Informações Adicionais" name="additionalInformation">
                                    <TextArea
                                        showCount
                                        style={{ height: 76 }}
                                        disabled={viewOnly}
                                    />
                                </FloatLabel>
                            </Card>

                            <Card title="Observações do usuário sobre o sistema">
                                <FloatLabel name="descriptionInformationFeedback">
                                    <TextArea
                                        showCount
                                        style={{ height: 76 }}
                                        disabled={viewOnly}
                                    />
                                </FloatLabel>
                            </Card>

                            {!viewOnly &&
                                <Row gutter={16} justify="end" style={{ padding: '0px 30px 0px 0px' }}>
                                    <Button type="primary" htmlType="submit">Próximo</Button>
                                </Row>
                            }
                        </Space>
                    </Form>
                </Space>
            </Content>
        </>
    );
}
export default CreateDescription;
