import React, { useState, useEffect, useContext, useRef, useReducer } from "react";
import "./index.css";
import GeoObjectForm from './GeoObjectForm';
import Hull from "./Hull";
import Properties from "./Properties";
import { Row, Col, Button, Space, Collapse, Layout, Breadcrumb, Typography, Card, Input, Form, Tabs } from "antd";
import { SmallDashOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { updateDescriptionObjectsFeedback, setSelectedObject, setSelectedContact, setDescriptionObjectsAction } from "../../../Store/actions";
import { store } from '../../../Store';
import { PercentageArea, ScatterChart, PieChart, BarChart, PitChart } from "./Properties/Charts";
import WindowPortal from "../../../WindowPortal";
import axios from "axios";
import { findObj } from "../../../../utils";

const { Link } = Typography;
const { Panel } = Collapse;
const { Header, Footer, Sider, Content } = Layout;
const { TextArea } = Input;

const removeUnecessaryAttributes = (tree) => {
    let remove_list = ["checkedsObjectsContacts", "selectedContact", "selectingContact", "deletedObject", "icon", "selectedObject"]
    const result = {};

    function traverse(node) {
        if (node === null) {
            return;
        }

        let localResult = node;

        // Checks if the properties to excludes are present in the node and remove then
        for (var property of remove_list) {
            if (node.hasOwnProperty(property)) {
                delete localResult[property];
            }
        }

        // Recursively loop through the node's children (if any)
        if (node.hasOwnProperty("children")) {
            if (Array.isArray(node.children)) {
                node.children.forEach((child, index) => {
                    localResult.children[index] = traverse(node.children[index]);
                });
            }
        }

        // Recursively loop through properties
        if (typeof localResult === 'object') {
            Object.keys(localResult).forEach(key => {
                localResult[key] = traverse(localResult[key]);
            });
        }

        return localResult;
    }

    Object.keys(tree).forEach(key => {
        result[key] = traverse(tree[key]);
    });

    return result;
}

const handleSubmit = event => {
    event.preventDefault();
    let geoDescriptionStored = {};
    geoDescriptionStored["descriptionInformation"] = JSON.parse(localStorage.descriptionInformation);
    geoDescriptionStored["descriptionObjects"] = JSON.parse(localStorage.descriptionObjects);

    axios.post('http://api-geoserver.inf.ufrgs.br/description/insert', { geoDescriptionStored })
        .then(res => {
            console.log(res);
            console.log(res.data);
            localStorage.descriptionInformation = "";
            localStorage.descriptionObjects = "";
            window.location = './description';
        })
}


function DescriptionObjects(props) {
    const globalState = useContext(store);
    const { state, dispatch } = globalState;
    const descriptionObjects = state.descriptionObjects;
    const [form] = Form.useForm();
    const { viewOnly } = props;
    const selectedObject = state.descriptionObjects.selectedObject;
    const selectedContact = state.descriptionObjects.contacts.selectedContact;
    const svgName = state.descriptionObjects.svgName || state.descriptionObjects.file?.filename; // retrocompatibilidade
    const [propertiesLabel, setPropertiesLabel] = useState("");
    const [width, setWidth] = useState(document.body.clientWidth * 1/4);
    const lineV = useRef(null);
    const [portalOpen, setPortalOpen] = useState(false);
    const [refresh, forceRefresh] = useReducer(x => x + 1, 0);

    useEffect(() => {
        dispatch(setDescriptionObjectsAction({
            ...state.descriptionObjects,
            selectedObject: [],
            contacts: {
                ...state.descriptionObjects.contacts,
                selectedContact: {}
            },
            pit: {}
        }));
        window.addEventListener('resize', forceRefresh);
        return () => window.removeEventListener('resize', forceRefresh);
    }, []);

    useEffect(() => {
        let propertiesLabel = "";
        if (selectedObject.length == 1) {
            const obj = findObj(descriptionObjects.objects, selectedObject[0]);
            propertiesLabel = obj.title;
        }
        setPropertiesLabel(propertiesLabel);
    }, [selectedObject, state.descriptionObjects.objects])

    const contentStyle = viewOnly ? {} : { padding: '0 30px' };

    const handleMouseDown = (e) => {
        const lineStyle = lineV.current.style;
        const clientWidth = document.body.clientWidth;
        const element = document.getElementById('layer');
        lineStyle.left = (e.clientX > clientWidth - 30) ? clientWidth - 30 : e.clientX + 'px';
        lineStyle.visibility = 'visible';
        if (element)
            element.style.pointerEvents = 'none';

        const handleMouseMove = (e) => {
            if (e.clientX < 30)
                lineStyle.left = '30px';
            else if (e.clientX > clientWidth - 30)
                lineStyle.left = clientWidth - 30;
            else
                lineStyle.left = e.clientX + 'px';
        }

        const handleMouseUp = (e) => {
            if (e.clientX < 30)
                setWidth(0)
            else if (e.clientX > clientWidth - 30)
                setWidth(clientWidth);
            else
                setWidth(e.clientX - 30);
            lineStyle.visibility = 'hidden';
            if (element)
                element.style.pointerEvents = 'auto';
            forceRefresh();
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };

        e.preventDefault();
        e.stopPropagation();
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    };

    const CustomHeader = () => {
        return (
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <span>Gráficos</span>
            <Button
                type="primary"
                icon={<ArrowRightOutlined style={{ display: "inline-flex" }} />}
                onClick={() => setPortalOpen(true)}
            >
                Janela Separada
            </Button>
          </div>
        );
      };


    const listNestedValues = (data) => {
        let nonEmptyValues = [];
        Object.entries(data).forEach(([key, value]) => {
            if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
                const nestedNonEmptyValues = listNestedValues(value);
                nonEmptyValues.push(...nestedNonEmptyValues);
            } else {
                if (key === "nextId") {
                    nonEmptyValues.push([]);
                }
                else
                    nonEmptyValues.push(value);
            }
        });

        return nonEmptyValues;
    };

    const allValuesEmpty = (data) => {
        let nonEmptyValues = listNestedValues(data);

        return Object.values(nonEmptyValues).every(value => {
            return value === "" || value === null || value.length === 0 || value === "dms";
        });
    };

    return (
        <>
            <Content style={contentStyle}>
                <form onSubmit={handleSubmit} id="geoObjectForm">
                    <Col>
                        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                            {!viewOnly && !state.isEditing &&
                                <Breadcrumb>
                                    <Breadcrumb.Item href="/">
                                        <Link>Home</Link>
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item href="/cdescription">
                                        <Link>Cadastro de Análogos</Link>
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                        Cadastro de Objetos
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            }

                            {!viewOnly && state.isEditing &&
                                <Breadcrumb>
                                    <Breadcrumb.Item href="/">
                                        <Link>Home</Link>
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item href="description">
                                        <Link>Busca de Análogos</Link>
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item href="cdescription">
                                        <Link>Editar Análogo</Link>
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                        Editar Objeto
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            }

                            <Row wrap={false}>
                                <Col
                                    flex={`${width}px`}
                                    style={{
                                        height: '100vh',
                                        position: 'sticky',
                                        top: 0,
                                        overflow: 'hidden'
                                    }}
                                >
                                    <GeoObjectForm viewOnly={viewOnly} />
                                </Col>

                                <Col
                                    flex={0}
                                    style={{
                                        height: '100vh',
                                        position: 'sticky',
                                        top: 0,
                                        display: 'flex',
                                        alignItems: 'center',
                                        cursor: "col-resize",
                                        backgroundColor: '#DCDCDC'
                                    }}
                                    onMouseDown={handleMouseDown}
                                >
                                    <SmallDashOutlined
                                        style={{
                                            fontSize: '8px',
                                            transform: 'rotate(90deg) scale(3, 1)',
                                        }}
                                    />
                                </Col>

                                <div id="line-V" ref={lineV}>
                                    <SmallDashOutlined
                                        style={{
                                            fontSize: '8px',
                                            transform: 'rotate(90deg) scale(3, 1)',
                                        }}
                                    />
                                </div>

                                <Col
                                    flex={1}
                                    style={{
                                        overflow: 'hidden'
                                    }}>
                                    <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                                        <Collapse defaultActiveKey={['0']}>
                                            <Panel header={"Imagem Vetorizada" + (svgName ? ": " + svgName : "")} key="0">
                                                <Hull viewOnly={viewOnly} refresh={refresh} />
                                            </Panel>
                                        </Collapse>

                                        {!portalOpen &&
                                            <Collapse defaultActiveKey={['1']}>
                                                <Panel key="0" header={<CustomHeader />} >
                                                    <Tabs
                                                        defaultActiveKey={1}
                                                        size="large"
                                                        centered
                                                        items={[
                                                            {
                                                                label: `Curva de proporção`,
                                                                key: 1,
                                                                children: <PercentageArea refresh={refresh}/>,
                                                            },
                                                            {
                                                                label: `Razão de aspecto`,
                                                                key: 2,
                                                                children: <ScatterChart refresh={refresh}/>,
                                                            },
                                                            {
                                                                label: `Proporção de elementos arquiteturais`,
                                                                key: 3,
                                                                children:
                                                                    <Row gutter={16}>
                                                                        <Col span={12}>
                                                                            <PieChart refresh={refresh}/>
                                                                        </Col>
                                                                        <Col span={12}>
                                                                            <BarChart refresh={refresh}/>
                                                                        </Col>
                                                                    </Row>,
                                                            },
                                                            {
                                                                label: "Visualização do Poço",
                                                                key: 4,
                                                                children: <PitChart refresh={refresh}/>,
                                                            },
                                                        ]}
                                                    />
                                                </Panel>
                                            </Collapse>
                                        }

                                        <Collapse defaultActiveKey={['1']} collapsible={!propertiesLabel ? "disabled" : ""}>
                                            <Panel header={"Propriedades 1" + (propertiesLabel && ": " + propertiesLabel)} key={propertiesLabel ? "1" : "0"}>
                                                <Properties index={0} viewOnly={viewOnly} />
                                            </Panel>
                                        </Collapse>

                                        <Collapse defaultActiveKey={['1']}>
                                            <Panel header="Contatos" key="0">
                                                Contatos
                                            </Panel>
                                        </Collapse>

                                        <Card title="Observações do usuário sobre o sistema">
                                            <TextArea
                                                showCount
                                                style={{ height: 76 }}
                                                value={state.descriptionObjects.descriptionObjectsFeedback}
                                                onChange={(e) => dispatch(updateDescriptionObjectsFeedback(e.target.value))}
                                                disabled={viewOnly}
                                            />
                                        </Card>

                                        {!viewOnly &&
                                            // <Row gutter={16} justify="end" style={{padding: '0px 30px'}}>
                                            //         <Button type="primary" htmlType="submit">Finalizar Cadastro</Button>
                                            // </Row>
                                            <Row gutter={16} justify="center" >
                                                <Col span={4}>
                                                    <Button type="primary" htmlType="submit">Finalizar Cadastro</Button>
                                                </Col>
                                            </Row>
                                        }
                                    </Space>
                                </Col>

                            </Row>
                        </Space>
                    </Col>
                </form>
            </Content>

            { portalOpen &&
                <WindowPortal setPortalOpen={setPortalOpen} forceRefresh={forceRefresh}>
                    <Tabs
                        defaultActiveKey={1}
                        size="large"
                        onChange={() => forceRefresh()}
                        // destroyInactiveTabPane
                        items={[
                            {
                                label: `Curva de proporção`,
                                key: 1,
                                children: <PercentageArea refresh={refresh}/>,
                            },
                            {
                                label: `Razão de aspecto`,
                                key: 2,
                                children: <ScatterChart refresh={refresh}/>,
                            },
                            {
                                label: `Proporção de elementos arquiteturais`,
                                key: 3,
                                children:
                                    <Row gutter={16}>
                                        <Col span={12}>
                                            <PieChart refresh={refresh}/>
                                        </Col>
                                        <Col span={12}>
                                            <BarChart refresh={refresh}/>
                                        </Col>
                                    </Row>,
                            },
                            {
                                label: "Visualização do Poço",
                                key: 4,
                                children: <PitChart refresh={refresh}/>,
                            },
                        ]}
                    />
                </WindowPortal>
            }
        </>
    );
}

export default DescriptionObjects;
