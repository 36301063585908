import React, { createContext, useEffect, useReducer } from 'react';
import { act } from 'react-dom/test-utils';
import { actions } from './actions';

import * as FaIcons from 'react-icons/fa';
import * as tbIcons from 'react-icons/tb';

import { ObjectTypes } from "../Description/CreateDescription/DescriptionObjects/types";

import Cryptic from "../../assets/cryptic.svg"
import Erosive from "../..//assets/erosive.svg"
import Faulted from "../../assets/faulted.svg"
import Gradational from "../../assets/gradational.svg"
import LagDeposit from "../../assets/lagDeposit.svg"
import Sharp from "../../assets/sharp.svg"
import Undefined from "../../assets/undefined.png"
import Icon from '@ant-design/icons';
import { initial } from 'lodash';

const { UNIT, ARCH_ELEMENT, FACIES_ASSOCIATION } = ObjectTypes;

const initialState = {
    descriptionInformation: {
        descriptionType: "",
        topDepth: "",
        bottomDepth: "",
        unit: "",
        coordinatesType: "dms",
        latitude: null,
        longitude: null,
        country: "",
        state: "",
        city: "",
        author: "",
        dataDate: "",
        docSrc: [],
        refLink: [],
        geologicalSiteName: "",
        watershed: "",
        fieldName: "",
        lithostratigraphyGroup: "",
        lithostratigraphyFormation: "",
        lithostratigraphyMember: "",
        geochronology: [],
        sedimentaryEnvironment: [],
        depositionalSystem: [],
        additionalInformation: "",
        descriptionInformationFeedback: ""
    },
    descriptionObjects: {
        selectedObject: [],
        nextId: {},
        objects: [],
        box: { update: 0 },
        pit: {},
        descriptionObjectsFeedback: "",
        file: {},
        deletedObject: {},
        contacts: {
            selectingContact: null,
            checkedsObjectsContacts: [],
            tree: [],
            nextId: 0,
            selectedContact: {},
        },
    },
    descriptionDataRetrieval: {},
    descriptionCharts: { "component": [], "key": [], "option": [] },
    isEditing: false

}

const store = createContext(initialState);
const { Provider } = store;

const descriptionReducer = (state, action) => {
    switch (action.type) {
        case actions.SET_EDITING_DESCRIPTION:
            console.log("actions.SET_EDITING_DESCRIPTION", action.value);
            return { ...state, isEditing: action.value };

            case actions.SET_DESCRIPTION_INFORMATION:
                console.log("actions.SET_DESCRIPTION_INFORMATION", action.value);
                return { ...state, descriptionInformation: action.value };

        case actions.SET_DESCRIPTION_OBJECTS:
            // console.log("actions.SET_DESCRIPTION_OBJECTS", action.value);
            return { ...state, descriptionObjects: action.value };

        case actions.ADD_DESCRIPTION_OBJECT:
            console.log("ADD_DESCRIPTION_OBJECT", action.value);
            return {
                ...state,
                descriptionObjects: {
                    ...state.descriptionObjects,
                    nextId: action.value.nextId,
                    objects: action.value.objects
                }
            }

        case actions.UPDATE_OBJECT_STRUCTURE:
        case actions.SET_OBJECT_PROPERTIES:
            console.log("UPDATE_OBJECT_STRUCTURE || SET_OBJECT_PROPERTIES", action.value);
            return {
                ...state,
                descriptionObjects: {
                    ...state.descriptionObjects,
                    objects: action.value
                }
            }

        case actions.SET_SELECTED_OBJECT:
            console.log("SET_SELECTED_OBJECT", action.value);
            return {
                ...state,
                descriptionObjects: {
                    ...state.descriptionObjects,
                    selectedObject: action.value,
                }
            }

        case actions.SET_SELECTED_OBJECT_PROPERTIES:
            console.log("SET_SELECTED_OBJECT_PROPERTIES", action.value);;
            return {
                ...state,
                descriptionObjects: {
                    ...state.descriptionObjects,
                    selectedObject: {
                        ...state.descriptionObjects.selectedObject,
                        properties: {
                            ...state.descriptionObjects.selectedObject.properties,
                            width: action.value.width,
                            thickness: action.value.thickness,
                        }
                    }
                }
            }

        case actions.DELETE_DESCRIPTION_OBJECT:
            console.log("DELETE_DESCRIPTION_OBJECT", action.value);
            return {
                ...state,
                descriptionObjects: {
                    ...state.descriptionObjects,
                    objects: action.value.objects,
                    selectedObject: action.value.selectedObject,
                    deletedObject: action.value.deletedObject
                }
            }

        case actions.UPDATE_DESCRIPTION_OBJECTS_FEEDBACK:
            console.log("UPDATE_DESCRIPTION_OBJECTS_FEEDBACK", action.value);;
            return {
                ...state,
                descriptionObjects: {
                    ...state.descriptionObjects,
                    descriptionObjectsFeedback: action.value,
                }
            }

        case actions.SELECTING_CONTACT:
            console.log("SELECTING_CONTACT", action.value);
            return {
                ...state,
                descriptionObjects: {
                    ...state.descriptionObjects,
                    contacts: {
                        ...state.descriptionObjects.contacts,
                        selectingContact: action.value,
                    }
                }
            }

        case actions.CHECKED_OBJECTS_CONTACTS:
            console.log("CHECKED_OBJECTS_CONTACTS", action.value);
            return {
                ...state,
                descriptionObjects: {
                    ...state.descriptionObjects,
                    contacts: {
                        ...state.descriptionObjects.contacts,
                        checkedsObjectsContacts: action.value,
                    }
                }
            }

        case actions.UPDATE_CONTACTS:
            console.log("UPDATE_CONTACTS", action.value);
            return {
                ...state,
                descriptionObjects: {
                    ...state.descriptionObjects,
                    contacts: {
                        ...state.descriptionObjects.contacts,
                        tree: action.value.tree,
                        nextId: action.value.nextId,
                        //id: action.value.nextId,
                    },
                }
            }

        case actions.SET_SELECTED_CONTACT:
            console.log("SET_SELECTED_CONTACT", action.value);
            return {
                ...state,
                descriptionObjects: {
                    ...state.descriptionObjects,
                    contacts: {
                        ...state.descriptionObjects.contacts,
                        selectedContact: action.value,
                    },
                }
            }

        case actions.SET_DESCRIPTION_DATA_RETRIEVAL:
            console.log(action.value);
            return {
                ...state,
                descriptionDataRetrieval: action.value
            }

        case actions.SET_DESCRIPTION_CHARTS:
            console.log(action.value);
            return {
                ...state,
                descriptionCharts: action.value
            }

        case actions.DELETE_DESCRIPTION_CHARTS:
            console.log(action.value);
            return {
                ...state,
                descriptionCharts: deleteDescriptionChartByIndex({ ...state.descriptionCharts }, action.value)
            }

        case actions.UPDATE_DESCRIPTION_CHARTS:
            console.log(action.value);
            return {
                ...state,
                descriptionCharts: {
                    key: [...state.descriptionCharts.key, action.value.key],
                    option: [...state.descriptionCharts.option, action.value.option],
                    component: [...state.descriptionCharts.component, action.value.component]
                }
            }

        case actions.SET_FILE:
            console.log(action.value);
            return {
                ...state,
                descriptionObjects: {
                    ...state.descriptionObjects,
                    file: action.value
                }
            }

        default:
            return state;
    };
}

const deleteDescriptionChartByIndex = (data, indexToDelete) => {
    return {
        key: data.key.filter((_, index) => index !== indexToDelete),
        option: data.option.filter((_, index) => index !== indexToDelete),
        component: data.component.filter((_, index) => index !== indexToDelete)
    };
}

const StateProvider = ({ children }) => {
    const getIconObjects = (type) => {
        switch (type) {
            case UNIT:
                return <tbIcons.TbChartRadar />;
            case FACIES_ASSOCIATION:
                return <FaIcons.FaRegCircle />;
            case ARCH_ELEMENT:
                return <tbIcons.TbChartBubble />;
            default:
                return;
        }
    }

    const getIconContacts = (type) => {
        switch (type) {
            case "Abrupto":
                return Sharp;
            case "Críptico":
                return Cryptic;
            case "De falha":
                return Faulted;
            case "Erosional":
                return Erosive;
            case "Gradacional":
                return Gradational;
            case "Linha de seixos":
                return LagDeposit;
            case "Indefinido":
                return Undefined;
            default:
                return;
        }
    }

    const addIconToObj = (node, index, array) => {
        let new_node = node;

        new_node.icon = getIconObjects(node.geologicalObjectType)

        if (node.hasOwnProperty('children'))
            new_node.children = node.children.map(addIconToObj);

        return new_node;
    }

    const addIconToContact = (node, index, array) => {
        let new_node = node;
        new_node.icon = <Icon component={() => (<img src={getIconContacts(node.typeContact)} style={{ padding: 1, width: 40, marginLeft: -13 }} />)} />
        return new_node;
    }

    const [state, dispatch] = useReducer(descriptionReducer, {}, () => {
        const localDescriptionInfo = localStorage.getItem('descriptionInformation');
        const localDescriptionObjects = localStorage.getItem('descriptionObjects');
        const localDescriptionDataRetrieval = localStorage.getItem('descriptionDataRetrieval');
        const localDescriptionCharts = localStorage.getItem('descriptionCharts');

        if (localDescriptionInfo)
            if (localDescriptionObjects) {
                const aux_descriptionObjects = JSON.parse(localDescriptionObjects);
                const objectsWithIcon = aux_descriptionObjects.objects.map(addIconToObj);
                const contactsWithIcon = aux_descriptionObjects.contacts.tree.map(addIconToContact);

                const infos = {
                    descriptionInformation: JSON.parse(localDescriptionInfo),
                    descriptionObjects: {
                        //selectedObject: aux_descriptionObjects.selectedObject,
                        //nextId: aux_descriptionObjects.nextId,
                        //contacts: aux_descriptionObjects.contacts,
                        ...aux_descriptionObjects,
                        box: { update: 0 },
                        objects: objectsWithIcon,
                        //descriptionObjectsFeedback: aux_descriptionObjects.descriptionObjectsFeedback,
                        contacts: {
                            ...aux_descriptionObjects.contacts,
                            tree: contactsWithIcon,
                        },
                    },
                    descriptionDataRetrieval: JSON.parse(localDescriptionDataRetrieval),
                    descriptionCharts: JSON.parse(localDescriptionCharts)

                };
                return infos;
            }
            else {
                return initialState
                //return { descriptionInformation: JSON.parse(localDescriptionInfo), descriptionObjects: initialState.descriptionObjects, descriptionDataRetrieval: initialState.descriptionDataRetrieval,  descriptionCharts: initialState.descriptionCharts}
                //return { descriptionInformation: JSON.parse(localDescriptionInfo), descriptionObjects: initialState.descriptionObjects}
            }
        else {
            return initialState;
        }

    });

    useEffect(() => {
        localStorage.setItem('descriptionInformation', JSON.stringify(state.descriptionInformation));
    }, [state.descriptionInformation]);


    useEffect(() => {
        const objectsWithIcon = state.descriptionObjects.objects.map(addIconToObj);
        const contactsWithIcon = state.descriptionObjects.contacts.tree.map(addIconToContact);

        const descriptionObjectsParsed = {
            //selectedObject: state.descriptionObjects.selectedObject,
            //nextId: state.descriptionObjects.nextId,
            //contacts: state.descriptionObjects.contacts,
            ...state.descriptionObjects,
            objects: objectsWithIcon,
            //descriptionObjectsFeedback: state.descriptionObjects.descriptionObjectsFeedback
            contacts: {
                ...state.descriptionObjects.contacts,
                tree: contactsWithIcon,
            }
        }

        localStorage.setItem('descriptionObjects', JSON.stringify(descriptionObjectsParsed));
    }, [state.descriptionObjects]);

    useEffect(() => {
        const descriptionObjectsParsed = { ...state.descriptionDataRetrieval }


        localStorage.setItem('descriptionDataRetrieval', JSON.stringify(descriptionObjectsParsed));
    }, [state.descriptionDataRetrieval]);

    useEffect(() => {
        const descriptionObjectsParsed = { ...state.descriptionCharts };

        localStorage.setItem('descriptionCharts', JSON.stringify(descriptionObjectsParsed));
    }, [state.descriptionCharts]);

    return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { store, StateProvider, initialState }
