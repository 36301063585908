import { useContext, useEffect } from "react";
import { store } from '../../../../Store';
import { addDescriptionObject, setSelectedObject } from '../../../../Store/actions';
import { FaRegCircle } from 'react-icons/fa';
import { TbChartRadar, TbChartBubble } from 'react-icons/tb';
import FloatLabel from "../../../../FloatLabel"
import {Button, Modal, Form, Input, Select } from 'antd';
import { ObjectTypes, ScaleTypes, UnitTypes, ArchitecturalElementOptions } from "../types";

const { UNIT, ARCH_ELEMENT, FACIES_ASSOCIATION } = ObjectTypes;

function addObj(data, parentKey, newObject, index = -1) {
    if (parentKey === null) {
        if (index === -1) return [...data, newObject];
        return [...data.slice(0, index), newObject, ...data.slice(index)];
    }

    return data.map(e => {
        if (e.key === parentKey) {
            const children = e.children || [];
            if (index === -1) {
                return { ...e, children: [...children, newObject] };
            } else {
                return {
                    ...e,
                    children: [...children.slice(0, index), newObject, ...children.slice(index)]
                };
            }
        }
        return { ...e, children: addObj(e.children, parentKey, newObject, index) };
    });
}

function getIcon(type) {
    const icons = {
        [UNIT]: <TbChartRadar />,
        [ARCH_ELEMENT]: <TbChartBubble />,
        [FACIES_ASSOCIATION]: <FaRegCircle />,
    };

    return icons.hasOwnProperty(type) ? icons[type] : null;
}

function longestCommonPrefix(strs) {
    if (strs.length === 0) return "";

    let prefix = strs[0];

    for (let i = 1; i < strs.length; i++) {
        while (strs[i].indexOf(prefix) !== 0) {
            prefix = prefix.slice(0, -1);
            if (prefix === "") return "";
        }
    }

    return prefix;
}

const findObj = (data, key) => {
    let result = null;
    const findData = (data, key) => {
        for (var i = 0; i < data.length; i++) {
            if (data[i].children)
                findData(data[i].children, key);
            if (data[i].key === key) {
                result = data[i];
            }
        }

        return result;
    }

    return findData(data, key);
}

function deleteObj(data, keys) {
    return data
        .filter(e => !keys.includes(e.key))
        .map(e => ({ ...e, children: deleteObj(e.children, keys) }));
}

function getFirstIndex(data, parentKey, keys) {
    let matchingIndex = -1;

    const search = (children) => {
        for (let index = 0; index < children.length; index++)
            if (keys.includes(children[index].key))
                return index;
        return -1;
    };

    if (parentKey !== null) {
        const obj = findObj(data, parentKey);
        if (obj && obj.children)
            matchingIndex = search(obj.children);
    } else {
        matchingIndex = search(data);
    }

    return matchingIndex;
}

function AddObjectModal(props) {
    const { setOpen, parentRef, mergeRef } = props;
    const globalState = useContext(store);
    const { state, dispatch } = globalState;
    const descriptionObjects = state.descriptionObjects;
    const objects = state.descriptionObjects.objects;
    const [form] = Form.useForm();

    useEffect(() => {
        if (mergeRef.current !== null) {
            const objs = mergeRef.current.map(key => findObj(objects, key));

            const setIfEqual = (field, values) => {
                const allSameValue = values.every((value, _, arr) => value === arr[0]);
                if (allSameValue) form.setFieldValue(field, values[0]);
            };

            form.setFieldValue('title', longestCommonPrefix(objs.map(obj => obj.title)));
            form.setFieldValue('geologicalObjectType', objs[1].geologicalObjectType);
            setIfEqual('unitType', objs.map(obj => obj.unitType));
            setIfEqual('architecturalElementType', objs.map(obj => obj.architecturalElementType));
            setIfEqual('scaleType', objs.map(obj => obj.properties?.scaleType));
        }
    }, []);

    const add = (form) => {
        const { title, geologicalObjectType, architecturalElementType, unitType, scaleType } = form.getFieldsValue();
        const nextId = (geologicalObjectType in descriptionObjects.nextId) ?
            descriptionObjects.nextId[geologicalObjectType] + 1 :
            1;
        const updatedNextId = {
            ...descriptionObjects.nextId,
            [geologicalObjectType]: nextId
        }
        const idNumber = nextId;
        const key = geologicalObjectType + nextId;
        let children = [];
        let properties = {};

        if (mergeRef.current !== null) {
            const objectsToMerge = mergeRef.current.map(key => findObj(objects, key));
            const len = objectsToMerge.length;
            children = objectsToMerge.reduce((acc, obj) => acc.concat(obj.children || []), []);
            properties = {
                polygonId: objectsToMerge.reduce((acc, obj) => acc.concat(obj?.properties?.polygonId || []), []),
                area: objectsToMerge.reduce((sum, obj) => sum + (obj?.properties?.area || 0), 0),
                width: objectsToMerge.reduce((sum, obj) => sum + (obj?.properties?.width || 0), 0),
                thickness: objectsToMerge.reduce((sum, obj) => sum + (obj?.properties?.thickness || 0), 0),
                heightMax: Math.max(...objectsToMerge.map(obj => obj?.properties?.heightMax || 0)),
                heightMean: objectsToMerge.reduce((sum, obj) => sum + (obj?.properties?.heightMean || 0), 0) / len,
                heightMedian: objectsToMerge.reduce((sum, obj) => sum + (obj?.properties?.heightMedian || 0), 0) / len,
                heightMin: Math.min(...objectsToMerge.map(obj => obj?.properties?.heightMin || 0)),
                heightMode: objectsToMerge.reduce((sum, obj) => sum + (obj?.properties?.heightMode || 0), 0) / len,
                widthMax: Math.max(...objectsToMerge.map(obj => obj?.properties?.widthMax || 0)),
                widthMean: objectsToMerge.reduce((sum, obj) => sum + (obj?.properties?.widthMean || 0), 0) / len,
                widthMedian: objectsToMerge.reduce((sum, obj) => sum + (obj?.properties?.widthMedian || 0), 0) / len,
                widthMin: Math.min(...objectsToMerge.map(obj => obj?.properties?.widthMin || 0)),
                widthMode: objectsToMerge.reduce((sum, obj) => sum + (obj?.properties?.widthMode || 0), 0) / len,
            };
        }

        const newObject = {
            title: title,
            key: key,
            idNumber: idNumber,
            geologicalObjectType: geologicalObjectType,
            properties: properties,
            icon: getIcon(geologicalObjectType),
            children: children,
        }

        if (geologicalObjectType === ARCH_ELEMENT) {
            newObject.architecturalElementType = architecturalElementType;
            newObject.properties.scaleType =  scaleType;
        } else if (geologicalObjectType == UNIT) {
            newObject.unitType = unitType;
        }

        const index = mergeRef.current !== null
            ? getFirstIndex(objects, parentRef.current, mergeRef.current)
            : -1;

        let newObjects = addObj(objects, parentRef.current, newObject, index);

        if (mergeRef.current !== null)
            newObjects = deleteObj(newObjects, mergeRef.current);

        dispatch(addDescriptionObject({
            nextId: updatedNextId,
            objects: newObjects,
        }));
        dispatch(setSelectedObject([key]));
    }
    
    return (
        <Modal
            title={mergeRef.current === null ? "Adicionar objeto geológico" : "Unir objetos geológicos"}
            open={true}
            style={{ top: 20 }}
            onCancel={() => setOpen(false)}
            footer={[
                <Button
                    key="back"
                    onClick={() => setOpen(false)}
                >
                    Cancelar
                </Button>,
                <Button
                    key="submit"
                    type="primary"
                    onClick={() => {
                        form.validateFields().then(() => {
                            add(form);
                            setOpen(false);
                        });
                    }}
                >
                    Adicionar
                </Button>,
            ]}
        >
            <Form
                name="addObject"
                form={form}
                layout="vertical"
                // onValuesChange={(e, f) => console.log(f)}
            >
                <FloatLabel label="Nome do Objeto" name="title" rules={[{ required: true }]}>
                    <Input style={{ width: '100%' }} />
                </FloatLabel>
                <FloatLabel label="Tipo do objeto geológico" name="geologicalObjectType" rules={[{ required: true }]}>
                    <Select
                        style={{ width: '100%' }}
                        disabled={mergeRef.current !== null}
                        options={Object.entries(ObjectTypes).map(([key, label]) => {
                            return { value: label, label: <> {getIcon(label)} {label} </> }
                        })}
                    />
                </FloatLabel>
                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) => prevValues.geologicalObjectType !== currentValues.geologicalObjectType}
                >
                    {({ getFieldValue }) =>
                        getFieldValue("geologicalObjectType") === UNIT && (
                            <FloatLabel label="Tipo da Unidade" name="unitType" rules={[{ required: true }]}>
                                <Select
                                    style={{ width: '100%' }}
                                    options={Object.entries(UnitTypes).map(([key, label]) => {
                                        return { label: label, value: label }
                                    })}
                                />
                            </FloatLabel>
                        )
                    }
                </Form.Item>
                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) => prevValues.geologicalObjectType !== currentValues.geologicalObjectType}
                >
                    {({ getFieldValue }) =>
                        getFieldValue("geologicalObjectType") === ARCH_ELEMENT && (
                            <>
                                <FloatLabel label="Tipo do Elemento Arquitetural" name="architecturalElementType" rules={[{ required: true }]}>
                                    <Select
                                        style={{ width: '100%' }}
                                        options={ArchitecturalElementOptions.map((x) => {
                                            return { label: x.label, value: x.key }
                                        })}
                                    />
                                </FloatLabel>
                                <FloatLabel label="Escala" name="scaleType" rules={[{ required: true }]}>
                                    <Select
                                        style={{ width: '100%' }}
                                        options={Object.entries(ScaleTypes).map(([key, label]) => {
                                            return { label: label, value: label };
                                        })}
                                    />
                                </FloatLabel>
                            </>
                        )
                    }
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default AddObjectModal;