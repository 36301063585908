import { useState, useEffect, useContext, useRef } from 'react';
import "./index.css";
import { EyeOutlined, EyeInvisibleOutlined, InboxOutlined, RedoOutlined, LoadingOutlined, LinkOutlined, SelectOutlined, AreaChartOutlined, LockOutlined, BarChartOutlined } from '@ant-design/icons';
import { Space, message, Upload, Spin, Button, Row, Col, Radio, Menu, Divider, Tooltip, Dropdown, Slider, Modal } from 'antd';
import svgPanZoom from 'svg-pan-zoom'
import { store, initialState } from '../../../../Store';
import { setObjectProperties, setFile, setSelectedObjectProperties, updateContacts, addDescriptionObject, setDescriptionObjectsAction } from '../../../../Store/actions';
import { HighlightSVG } from "../highlightSVG"
import Window from "../../../../Window"
import * as FaIcons from 'react-icons/fa';
import * as tbIcons from 'react-icons/tb';
import { setSelectedObject } from '../../../../Store/actions';
import { getFileFromCache } from '../../../../../utils';
import axios from "axios";
import { ObjectTypes } from "../types";
import { PitChart } from '../Properties/Charts';
// import Slider from "../../../../Slider";
import { ExclamationCircleFilled } from '@ant-design/icons';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const { Dragger } = Upload;

function Hull(props) {
    const globalState = useContext(store);
    const { state, dispatch } = globalState;
    const { viewOnly, refresh } = props;
    const [selectedFile, setSelectedFile] = useState();
    const [selectedPolygon, setSelectedPolygon] = useState();
    const [preview, setPreview] = useState();
    const [layers, setLayers] = useState([]);
    const [selectedObjectState, setSelectedObjectState] = useState(state.descriptionObjects.selectedObject)
    const [selectedContact, setSelectedContact] = useState(state.descriptionObjects.contacts.selectedContact)
    const [descriptionObjects, setDescriptionObjects] = useState(state.descriptionObjects.objects)
    const [contactsData, setContactsData] = useState(state.descriptionObjects.contacts);
    const [visible, setVisible] = useState({
        "Legenda": true,
        "Escala": true,
        "Interunidade": true,
        "Complexo": true,
        "Composto": true,
        "Individuo": true,
        "Associação de Fácies": true,
    })
    const [scaleDomain, setScaleDomain] = useState({});
    const [scaleRange, setScaleRange] = useState({});
    const [polygonBBox, setPolygonBBox] = useState({});
    const [loading, setLoading] = useState(false);
    const [svgPan, setSvgPan] = useState();
    const [ctm, setCTM] = useState({ a: 0, b: 0, c: 0, d: 0, e: 0, f: 0 });
    const [mode, setMode] = useState(0);
    const [value, setValue] = useState(0);
    const [svgDimensions, setSvgDimensions] = useState({ width: 0, height: 0 });
    const ref = useRef();

    const showModal = (updatedNextId, resultTree, onError, file) => {
        Modal.confirm({
            title: 'Você gostaria de manter os objetos já criados na sua árvore?',
            icon: <ExclamationCircleFilled />,
            content: (
                <div>
                    <p>Você está carregando um SVG que possui um conjunto de objetos predefinidos.</p>
                    <p><b>Caso escolha não, todo os objetos da árvore serão removidos</b></p>
                </div>
            ),
            okText: 'Não',
            cancelText: 'Sim',
            onOk() {
                handleOk(updatedNextId, resultTree, onError, file);
            },
            onCancel() {
                handleCancel(onError, file);
            },
        });
    };

    const handleOk = (updatedNextId, resultTree, onError, file) => {
        try {
            cacheFileAsDataUrl(file)
                .then(result => {
                    dispatch(setDescriptionObjectsAction({
                        ...initialState.descriptionObjects,
                        nextId: updatedNextId,
                        objects: resultTree,
                        file: result
                    }))
                })
            message.success(`Upload do arquivo ${file.name} concluído.`);
        } catch (error) {
            onError();
            message.error(`Upload do arquivo ${file.name} falhou.`);
        }

    };

    const handleCancel = (onError, file) => {
        try {
            cacheFileAsDataUrl(file)
                .then(result => {
                    dispatch(setDescriptionObjectsAction({
                        ...state.descriptionObjects,
                        nextId: state.descriptionObjects.nextId,
                        objects: descriptionObjects,
                        file: result
                    }))
                })
            message.success(`Upload do arquivo ${file.name} concluído.`);
        } catch (error) {
            onError();
            message.error(`Upload do arquivo ${file.name} falhou.`);
        }
    };

    useEffect(() => {
        const file = getFileFromCache(state)?.originFileObj;
        setSelectedFile(file);
    }, [state.descriptionObjects.file])

    useEffect(() => {
        setSelectedObjectState(state.descriptionObjects.selectedObject);
        if (mode === 1)
            setMode(0);
    }, [state.descriptionObjects.selectedObject])

    useEffect(() => {
        setDescriptionObjects(state.descriptionObjects.objects);
        const found = state.descriptionObjects.objects.find(element => element.key === state.descriptionObjects.selectedObject.key);
        if (found !== undefined)
            dispatch(setSelectedObject(found));
    }, [state.descriptionObjects.objects])

    useEffect(() => {
        setSelectedContact(state.descriptionObjects.contacts.selectedContact);
    }, [state.descriptionObjects.contacts.selectedContact])

    useEffect(() => {
        layers.map((layer) => {
            if (visible[layer.prettyName])
                layer.style.display = "block";
            else
                layer.style.display = "none";
            return layer;
        })
    }, [visible])

    useEffect(() => {
        if (ref.current)
            setSvgDimensions({ width: ref.current.clientWidth, height: ref.current.clientHeight })
    }, [refresh, mode])

    //Updating object and contact tree when change objects name by click
    useEffect(() => {
        if (mode === 2) {
            dispatch(setSelectedObject(findObj(state.descriptionObjects.objects, selectedPolygon)));
        }

        if (mode === 1) {
            // const highlightSVG = new HighlightSVG("selected");
            // const scale = {
            //     getRealWidth: getRealWidth,
            //     getRealHeight: getRealHeight
            // }

            // const polygonProperties = highlightSVG.getPolygonProperties(selectedPolygon, scale, polygonBBox);
            // const polygonProperties = {};

            // if (selectedPolygon) {
            //     dispatch(
            //         setObjectProperties(
            //             updateObjectHull(
            //                 selectedObjectState[0],
            //                 descriptionObjects,
            //                 selectedPolygon,
            //                 polygonProperties,
            //             )
            //         )
            //     );

            //     dispatch(
            //         updateContacts(
            //             {
            //                 ...state.descriptionObjects.contacts,
            //                 tree: updateContactHull(
            //                     selectedObjectState[0],
            //                     state.descriptionObjects.contacts.tree,
            //                     selectedPolygon
            //                 )
            //             }

            //         )
            //     );
            // }
        }
    }, [selectedPolygon])


    //Draw object
    //TODO: Draw contact
    useEffect(() => {
        let highlightSVG = new HighlightSVG("selected");
        highlightSVG.cleanSVG();
        highlightSVG.selectSVG(selectedObjectState, state.descriptionObjects.objects);
    }, [selectedObjectState, layers, state.descriptionObjects.objects])

    // create a preview as a side effect, whenever selected file is changed
    useEffect(() => {
        if (!selectedFile) {
            setPreview(undefined)
            return;
        } else {
            const objectUrl = URL.createObjectURL(selectedFile);
            setPreview(objectUrl);
            return () => URL.revokeObjectURL(objectUrl);
        }
    }, [selectedFile])

    useEffect(() => {
        if (svgPan) {
            svgPan.resize();
            svgPan.fit();
            svgPan.center();
        }
    }, [refresh]);

    useEffect(() => {
        dispatch(setDescriptionObjectsAction({
            ...state.descriptionObjects,
            box: { ...state.descriptionObjects.box, ctm: ctm }
        }));
    }, [ctm])

    const loadSVG = () => {
        getLayers();
        handleOnload();

        const svgPan = svgPanZoom('#envoltória-objetos-geologicos', {
            zoomEnabled: true,
            controlIconsEnabled: true,
            mouseWheelZoomEnabled: true,
            maxZoom: 100,
            zoomScaleSensitivity: 0.3,
            onPan: () => {
                //TODO: prevent user to select polygons
            },
            onUpdatedCTM: (e) => {
                setCTM(e)
            }
        });
        setSvgPan(svgPan);
    }

    const getLayers = () => {
        const obj = document.querySelector("object");
        const svgDoc = obj.contentDocument;
        let rawLayers = Array.from(svgDoc.querySelectorAll("svg>g"));

        let layers = rawLayers.map(e => {
            e.prettyName = e.getAttribute("inkscape:label")
            return e;
        })

        layers = layers.reverse();
        setLayers(layers);

    }

    const onPolygonClick = (elem, svgDoc) => {
        if (!viewOnly) {
            const box = elem.getBBox();
            setPolygonBBox({ x: box.x, y: box.y, width: box.width, height: box.height });
            setSelectedPolygon(elem.id);
        }
    }

    const updateObjectHull = (key, data, polygonsId, polygonProperties) => {
        const updateData = (data, key) => data.map(e => {
            if (e.children)
                e.children = updateData(e.children, key);
            if (e.key === key) {
                return {
                    ...e,
                    properties: {
                        ...e.properties,
                        polygonId: polygonsId,
                        width: getRealWidth(polygonBBox.width),
                        thickness: getRealHeight(polygonBBox.height),
                        ...polygonProperties,
                    }
                };
            }

            return e;
        })

        const newArray = updateData(data, key);

        return newArray;
    }

    const updateContactHull = (key, data, polygonId) => {
        const updateData = (data, key) => data.map(e => {
            if (e.children)
                e.children = updateData(e.children, key);
            else if (e.original_key === key) {
                return {
                    ...e,
                    title: polygonId,
                    polygonId: [polygonId],
                };
            }
            return e;
        })
        const newArray = updateData(data, key);
        return newArray;
    }

    const handleOnload = () => {
        const obj = document.querySelector("object");
        const svgDoc = obj.contentDocument;
        const textContentWithoutHover = ".svg-pan-zoom_viewport path, .svg-pan-zoom_viewport polygon {pointer-events:all;} .svg-pan-zoom_viewport path.selected, .svg-pan-zoom_viewport polygon.selected { stroke: #ff00ff !important; stroke-width:6px !important; vector-effect: non-scaling-stroke !important; } "
        const textContent = ".svg-pan-zoom_viewport path, .svg-pan-zoom_viewport polygon {pointer-events:all;} .svg-pan-zoom_viewport path:hover, .svg-pan-zoom_viewport polygon:hover, .svg-pan-zoom_viewport path.selected, .svg-pan-zoom_viewport polygon.selected { stroke: #ff00ff !important; stroke-width:6px !important; vector-effect: non-scaling-stroke !important; } ";
        let styleElement = svgDoc.createElementNS("http://www.w3.org/2000/svg", "style");
        styleElement.textContent = viewOnly ? textContentWithoutHover : textContent;
        svgDoc.querySelector("svg").appendChild(styleElement);
        let polygons = Array.from(svgDoc.querySelectorAll("path, polygon"));
        polygons.forEach(elem => {
            elem.onclick = () => {
                onPolygonClick(elem, svgDoc);
            }
        })

        // Get scale text and remove unit with regex (m, km)
        const scaleDomainX = Number(svgDoc.querySelector("#ESCALA_HORIZONTAL text").textContent.replace(/\D/g, ""));
        const scaleDomainY = Number(svgDoc.querySelector("#ESCALA_VERTICAL text").textContent.replace(/\D/g, ""));
        setScaleDomain({ x: scaleDomainX, y: scaleDomainY })

        // Get the canvas width and height of scale
        const scaleRangeX = Number(svgDoc.querySelector("#ESCALA_HORIZONTAL rect").getAttribute("width"));
        const scaleRangeY = Number(svgDoc.querySelector("#ESCALA_VERTICAL rect").getAttribute("height"));
        setScaleRange({ x: scaleRangeX, y: scaleRangeY });

    }

    const getRealWidth = (width) => {
        return (scaleDomain.x * width) / scaleRange.x;
    }

    const getRealHeight = (height) => {
        return (scaleDomain.y * height) / scaleRange.y;
    }

    const cacheFileAsDataUrl = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = function (e) {
                const dataUrl = e.target.result;
                resolve({
                    filename: file.name,
                    dataUrl: dataUrl
                });
            };
            reader.onerror = function (error) {
                reject(error);
            };
            reader.readAsDataURL(file);
        });
    };

    const customRequest = async ({ file, onSuccess, onError }) => {
        if (viewOnly) {
            onSuccess("ok", null);
            cacheFileAsDataUrl(file)
                .then(result => dispatch(setFile(result)))
        } else {
            const response = await uploadSvgToBackend(file);
            if (response.status === 200) {
                onSuccess("ok", null);
                const [resulTree, updatedNextId] = buildTree(
                    response.data,
                    initialState.descriptionObjects.nextId
                );
                showModal(updatedNextId, resulTree, onError, file);
            } else {
                onError();
                message.error(`Upload do arquivo ${file.name} falhou.`);
            }
        }
    };

    const uploadSvgToBackend = async (file) => {
        const url = 'http://api-geoserver.inf.ufrgs.br/svg/process_tree_contact';
        const formData = new FormData();
        formData.append('svgFile', file);
        try {
            const response = await axios.post(url, formData)
            return response
        } catch (error) {
            throw error;
        }
    };

    const buildTree = (tree, nextId) => {
        const { UNIT, ARCH_ELEMENT, FACIES_ASSOCIATION } = ObjectTypes;
        const layersMap = {
            'INTERUNIDADE': UNIT,
            'COMPLEXO': ARCH_ELEMENT,
            'COMPOSTO': ARCH_ELEMENT,
            'INDIVIDUO': ARCH_ELEMENT,
            'ASSOCIACAO_DE_FACIES': FACIES_ASSOCIATION,
        };

        const getIcon = (type) => {
            switch (type) {
                case UNIT:
                    return <tbIcons.TbChartRadar />;
                case FACIES_ASSOCIATION:
                    return <FaIcons.FaRegCircle />;
                case ARCH_ELEMENT:
                    return <tbIcons.TbChartBubble />;
                default:
                    return;
            }
        }

        const recursiveBuildObjects = (data) => {
            const geologicalObjectType = data.type;
            const id = (geologicalObjectType in nextId) ? nextId[geologicalObjectType] + 1 : 1;
            const key = geologicalObjectType + id;
            nextId[geologicalObjectType] = id;

            const children = data.children ? data.children.map(child => recursiveBuildObjects(child)) : [];
            return {
                title: data.label,
                key: key,
                idNumber: id,
                properties: {
                    polygonId: [data.id],
                    area: data.area,
                    heightMean: data.heightMean,
                    heightMedian: data.heightMedian,
                    heightMode: data.heightMode,
                    heightMax: data.heightMax,
                    heightMin: data.heightMin,
                    widthMean: data.widthMean,
                    widthMedian: data.widthMedian,
                    widthMode: data.widthMode,
                    widthMax: data.widthMax,
                    widthMin: data.widthMin,
                    width: data.width,
                    thickness: data.thickness
                },
                bbox: {},
                geologicalObjectType: geologicalObjectType,
                children: children,
                icon: getIcon(geologicalObjectType),
            }
        }

        const resultTree = tree.children.map(object => recursiveBuildObjects(object))
        return [resultTree, nextId]
    };

    const draggerProps = {
        name: 'file',
        maxCount: 1,
        accept: 'image/svg+xml',
        defaultFileList: () => {
            const file = getFileFromCache(state);
            return file ? [file] : undefined;
        },
        onRemove() {
            dispatch(setDescriptionObjectsAction(initialState.descriptionObjects))
        }
    };

    const items = layers.map(e => {
        return (
            {
                label: e.prettyName,
                key: e.prettyName,
                icon: visible[e.prettyName] ? <EyeOutlined /> : <EyeInvisibleOutlined />,
            }
        );
    })

    const menuProps = {
        items: items,
        onClick: (e) => {
            const { key } = e;
            setVisible((state) => ({ ...state, [key]: !state[key] }))
            e.stopPropagation();
        },
    };

    const modeOnChange = (value) => {
        dispatch(setDescriptionObjectsAction({
            ...state.descriptionObjects,
            pit: (value === 4) ? { ctm: ctm, left: 0 } : {}
        }));
        setMode(value === mode ? 0 : value);
    }

    return (
        <>
            <div>
                <Spin indicator={antIcon} spinning={loading} tip="Loading">
                    <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                        {selectedFile && (
                            <>
                                <Row id="layer" gutter={0}>
                                    <Col span={24}>
                                        <object
                                            data={preview}
                                            id="envoltória-objetos-geologicos"
                                            type="image/svg+xml"
                                            width="100%"
                                            alt="Objetos"
                                            aria-label="Objetos"
                                            onLoad={loadSVG}
                                            ref={ref}
                                            style={{ maxHeight: window.innerHeight / 3 }}
                                        />
                                        <div className="svg-toolbar">
                                            <Row align='middle'>
                                                <Tooltip title="Atribuição">
                                                    <Button
                                                        type={mode === 1 ? 'primary' : 'default'}
                                                        onClick={() => modeOnChange(1)}
                                                        style={{ borderColor: '#ffffff' }}
                                                        disabled={selectedObjectState.length != 1}
                                                    >
                                                        <LinkOutlined style={{ display: "inline-flex" }} />
                                                    </Button>
                                                </Tooltip>
                                                <Tooltip title="Seleção">
                                                    <Button
                                                        type={mode === 2 ? 'primary' : 'default'}
                                                        onClick={() => modeOnChange(2)}
                                                        style={{ borderColor: '#ffffff' }}
                                                    >
                                                        <SelectOutlined style={{ display: "inline-flex" }} />
                                                    </Button>
                                                </Tooltip>
                                                <Tooltip title="Curva de proporção">
                                                    <Button
                                                        type={mode === 3 ? 'primary' : 'default'}
                                                        onClick={() => modeOnChange(3)}
                                                        style={{ borderColor: '#ffffff' }}
                                                    >
                                                        <AreaChartOutlined style={{ display: "inline-flex" }} />
                                                    </Button>
                                                </Tooltip>
                                                <Tooltip title="Visualização do poço">
                                                    <Button
                                                        type={mode === 4 ? 'primary' : 'default'}
                                                        onClick={() => modeOnChange(4)}
                                                        style={{ borderColor : '#ffffff'}}
                                                    >
                                                        <BarChartOutlined style={{ display: "inline-flex" }}/>
                                                    </Button>
                                                </Tooltip>
                                                <Tooltip title="Camadas">
                                                    <Dropdown menu={menuProps}>
                                                        <Button
                                                            style={{ borderColor : '#ffffff' }}
                                                        >
                                                            <EyeOutlined style={{ marginLeft: 0, display: "inline-flex" }} />
                                                        </Button>
                                                    </Dropdown>
                                                </Tooltip>
                                            </Row>
                                        </div>
                                        { mode == 3 && <Window ctm={ctm} /> }
                                        { mode == 4 &&
                                            <>
                                                <div id="slider-container">
                                                    <div
                                                        id="vertical-line"
                                                        style={{
                                                            left: value - 1,
                                                            top: -(svgDimensions.height + 6),
                                                            height: (svgDimensions.height + 6),
                                                        }}
                                                    >
                                                    </div>
                                                    <Slider
                                                        defaultValue={30}
                                                        max={svgDimensions.width}
                                                        value={value}
                                                        onChange={(value) => setValue(value)}
                                                        onAfterChange={(value) =>  dispatch(setDescriptionObjectsAction({
                                                            ...state.descriptionObjects,
                                                            pit: { ctm: ctm, left: value }
                                                        }))}
                                                        style={{ margin: 0, padding: 0 }}
                                                        tooltip={{ formatter: null }}
                                                        keyboard={false}
                                                    />
                                                </div>
                                            </>
                                        }
                                    </Col>
                                </Row>
                            </>
                        )}
                        <Dragger
                            {...draggerProps}
                            customRequest={customRequest}
                        >
                            <p className="ant-upload-text">Clique ou arraste um arquivo para a área de upload</p>
                            <p className="ant-upload-hint">
                                Suporte apenas para arquivos svg.
                            </p>
                        </Dragger>
                    </Space>
                </Spin>
            </div>
        </>
    )
}

export default Hull;

function findObj(data, key) {
    const stack = data.slice();

    while (stack.length > 0) {
        const current = stack.pop();
        if (current.properties.polygonId.includes(key))
            return [current.key];
        else if (current.children && current.children.length > 0)
            stack.push(...current.children);
    }

    return [];
}
