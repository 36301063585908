import { useContext, useState, useEffect, useRef } from "react";
import { Tree, Row, Col, Input, Dropdown, Check, Checkbox } from 'antd';
import { DeleteOutlined, EditOutlined, CopyOutlined, MoreOutlined } from '@ant-design/icons';
import { Space, Popconfirm, Card } from 'antd';
import { store } from '../../../../Store';
import { updateContacts, setCheckedsObjectsContacts, setSelectedContact, setSelectedObject, deleteDescriptionObject, setObjectProperties  } from '../../../../Store/actions';
import { HighlightSVG } from "../highlightSVG"
import Icon from '@ant-design/icons';
import Cryptic from "../../../../../assets/cryptic.svg"
import Erosive from "../../../../../assets/erosive.svg"
import Faulted from "../../../../../assets/faulted.svg"
import Gradational from "../../../../../assets/gradational.svg"
import LagDeposit from "../../../../../assets/lagDeposit.svg"
import Sharp from "../../../../../assets/sharp.svg"
import Undefined from "../../../../../assets/undefined.png"
import Swal from 'sweetalert2';

import { debounce } from 'lodash';

const { Search } = Input;

function ContactsForm(props) {
    const {viewOnly, openContactsSearch, deletingContacts} = props
    const globalState = useContext(store);
    const { state, dispatch } = globalState;

    const [gData, setGData] = useState(state.descriptionObjects.objects);
    const [contactsData, setContactsData] = useState(state.descriptionObjects.contacts);
    const [typeContact, setTypeContact] = useState(state.descriptionObjects.contacts.selectingContact)
    const [deletedObject, setDeletedObject] = useState(state.descriptionObjects.deletedObject)
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [isHovered, setIsHovered] = useState(null);
    const [contextMenu, setContextMenu] = useState({
        visible: false,
        node: null,
    });
    const [edit, setEdit] = useState({
        key: null,
        value: '',
        newKey: false,
        update: false
    });
    const [checkStatus, setCheckStatus] = useState({
        visible: false,
        checkStrictly: false,
        checkeds: null,
    });
    const [selectAllStatus, setSelectAllStatus] = useState({
        visible: false,
        checked: false
    });
    const inputRef = useRef(null);
    const contactsRef = useRef(null);
    const doubleClickTimeoutRef = useRef(null);
    // Search
    const [expandedKeys, setExpandedKeys] = useState([])
    const [autoExpandParent, setAutoExpandParent] = useState(true);

    useEffect(() => {
        if (state.descriptionObjects.contacts.selectedContact.key)
            setSelectedKeys([state.descriptionObjects.contacts.selectedContact.key]);
        else
            setSelectedKeys([]);
    }, [state.descriptionObjects.contacts.selectedContact])

    //Search
    const onExpand = (newExpandedKeys) => {
        setExpandedKeys(newExpandedKeys);
        setAutoExpandParent(true);
    };

    useEffect(() => {
        const { checkeds } = checkStatus;
        if(deletingContacts) {
            setCheckStatus({ visible: true, checkeds: [], checkStrictly: false });
            setSelectAllStatus({ visible: true, checked: false });
        }
        if (!deletingContacts && checkeds !== null) {
            if (checkeds.length > 0) {
                Swal.fire({
                    title: 'Você tem certeza que deseja remover estes objetos?',
                    text: "Não será possível reverter isto!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Sim, apague!',
                    cancelButtonText: 'Não'
                }).then((result) => {
                    if (result.isConfirmed) {
                        const newContacts = {
                            tree: deleteContact(checkeds),
                            nextId: state.descriptionObjects.contacts.nextId
                        }
                        dispatch(updateContacts(newContacts));
                    }
                    setSelectAllStatus({ visible: false, checked: false });
                    setCheckStatus({ visible: false, checkeds: null, checkStrictly: false })
                })
            } else {
                setSelectAllStatus({ visible: false, checked: false });
                setCheckStatus({ visible: false, checkeds: null, checkStrictly: false })
            }
        }
    }, [deletingContacts])

    function traverseTree(data) {
        const stack = [...data];
        const result = [];
    
        while (stack.length > 0) {
            const node = stack.pop();
            if (node.children) {
                result.push(node.key);
            }
        }
    
        return result;
    }

    function findNodesWithSubstring(tree, substring) {
        const result = [];

        function traverse(node) {
            if (node === null || typeof node !== 'object') {
                return;
            }

            // Checks if the substring is present in the node key
            if (substring != "" && node.hasOwnProperty('title') && typeof node.title === 'string') {
                let key = node.title.toLowerCase();
                if (key.includes(substring.toLowerCase())) {
                    result.push(node.key);
                }
            }

            // Recursively loop through the node's children (if any)
            if (Array.isArray(node.children)) {
                node.children.forEach(child => {
                    traverse(child);
                });
            }
        }

        tree.forEach(node => traverse(node));

        return result;
    }

    function updateTitles(treeInput, nodesWithSubstring, substring) {
        var tree = treeInput;
        function traverseAndUpdate(node) {
            console.log(node)
            if (node === null || typeof node !== 'object') {
                return;
            }

            // If the node's key is in the nodesWithSubstring list, update the title
            if (node.hasOwnProperty('key') && typeof node.key === 'string') {
                let title = node.title;
                let key = node.key;
                if (substring != "" && nodesWithSubstring.includes(key)) {
                    let index = title.toLowerCase().indexOf(substring.toLowerCase());

                    let before = title.substring(0, index);
                    let match = title.substring(index, index + substring.length);
                    let after = title.substring(index + substring.length);

                    node.before = before;
                    node.match = match;
                    node.after = after;
                }
                else {
                    node.match = "";
                }
            }

            // Recursively loop through the node's children (if any)
            if (Array.isArray(node.children)) {
                node.children.forEach(child => {
                    traverseAndUpdate(child);
                });
            }
        }

        tree.forEach(node => traverseAndUpdate(node));
    }

    
    const onChange = (e) => {
        const { value } = e.target;
        let newExpandedKeys = findNodesWithSubstring(contactsData.tree, value);
        console.log(newExpandedKeys)
        setExpandedKeys(newExpandedKeys);
        setAutoExpandParent(true);
        updateTitles(contactsData.tree, newExpandedKeys, value);
        onExpand(newExpandedKeys);
        if (newExpandedKeys.length >= 1)
            contactsRef.current.scrollTo({ key: newExpandedKeys[0], align: "top" });
    };

    const getIcon = (type) => {
        switch (type) {
            case "Abrupto":
                return Sharp;
            case "Críptico":
                return Cryptic;
            case "De falha":
                return Faulted;
            case "Erosional":
                return Erosive;
            case "Gradacional":
                return Gradational;
            case "Linha de seixos":
                return LagDeposit;
            case "Indefinido":
                return Undefined;
            default:
                return;
        }
    }

    //Set the type of the last added contact
    useEffect(() => {
        setTypeContact(state.descriptionObjects.contacts.selectingContact);
    }, [state.descriptionObjects.contacts.selectingContact])

    //Set last deleted object
    useEffect(() => {
        setDeletedObject(state.descriptionObjects.deletedObject);
    }, [state.descriptionObjects.deletedObject])

    //Delete all object's contact
    useEffect(() => {
        let selectedContact = state.descriptionObjects.contacts.selectedContact;
        console.log('deletedObject id:')
        console.log(deletedObject.id)
        if (deletedObject && Object.hasOwn(deletedObject, "id")) {
            deleteContact(deletedObject.id);

            // if the user deletes a selected contact, we need close its informations
            let foundValue = deletedObject.id.includes(selectedContact.key);
            
            if (foundValue)
                dispatch(setSelectedContact({}));

            dispatch(deleteDescriptionObject({
                objects: state.descriptionObjects.objects,
                selectedObject: state.descriptionObjects.selectedObject,
                deletedObject: {}
            }));


        }
    }, [deletedObject])

    useEffect(() => {
        if(!openContactsSearch)
            updateTitles(contactsData.tree, [], "key0");
    }, [openContactsSearch])

    //Update the tree
    useEffect(() => {
        setGData(state.descriptionObjects.objects);
    }, [state.descriptionObjects.objects])

    //Update the tree
    useEffect(() => {
        setContactsData(
            {
                ...contactsData,
                tree: state.descriptionObjects.contacts.tree,
            }
        );
    }, [state.descriptionObjects.contacts.tree])

    

    //Add the last contact to the tree
    //That contact start opened in the tree
    useEffect(() => {
        const order = contactsData.nextId + 1;

        function createJson(node) {
            return { key: node.key + "_" + order, original_key: node.key, title: node.title, checkable: false };
        }

        var children;

        children = state.descriptionObjects.contacts.checkedsObjectsContacts.map(createJson);

        if (children.length !== 0) {
            var title = "Contato " + order + " (" + typeContact + ")";
            var key = "contacts" + order;

            const node = {
                title: title,
                key: key,
                children: children,
                typeContact: typeContact,
                icon: <Icon component={() => (<img src={getIcon(typeContact)} style={{ padding: 1, width: 40, marginLeft: -13 }} />)} />,
            }

            setContactsData(
                {
                    ...contactsData,
                    tree: [...contactsData.tree, node],
                    nextId: contactsData.nextId + 1
                }
            );

            setExpandedKeys(oldArray => [...oldArray, key])


            dispatch(setCheckedsObjectsContacts([]));

        }

    }, [state.descriptionObjects.contacts.checkedsObjectsContacts])

    //Update the contacts tree
    useEffect(() => {
        dispatch(updateContacts(contactsData));
    }, [contactsData])

    const deleteContact = (keys) => {
        
        function removeContact(key, contactsTree) {
            if (key.includes("contact")) {
                return contactsTree.filter((contact) => contact.key !== key);
            }
            else {
                return contactsTree.filter((contact) => !contact.children.some((child) => child.original_key === key));
            }
        }

        let highlightSVG;
        let newContactsTree = contactsData.tree;

        for (const key of keys) {
            newContactsTree = removeContact(key, newContactsTree);
            highlightSVG = new HighlightSVG("selected");
            highlightSVG.cleanSVG();
        }

        setContactsData(
            {
                ...contactsData,
                tree: newContactsTree,
            }
        );
    }


    function getItem(label, key, icon, children, theme) {
        return {
            key,
            icon,
            children,
            label,
            theme,
        };
    }

    const displayInline = { display: "inline-flex" };

    const onClickOutside = () => {
        setContextMenu({ ...contextMenu, visible: false, node: null });
        document.removeEventListener('click', onClickOutside);
    }

    useEffect(() => {
        if (contextMenu.visible) {
            document.addEventListener('click', onClickOutside);
        }
    }, [contextMenu])

    useEffect(() => {
        const objects = state.descriptionObjects.objects;
        const contacts = contactsData.tree;

        if (edit.newKey) {
            inputRef.current.focus();
        }

        if (edit.update) {
            const newContactsData = contacts.map(contact => {
                if (contact.key === edit.key) {
                    return { ...contact, title: edit.value }
                } else {
                    return contact;
                }
            });
            setContactsData({
                ...contactsData,
                tree: newContactsData,
            });
            dispatch(setObjectProperties(objects));
            setEdit({ key: null, value: '', newKey: false, update: false });
        }
    }, [edit]);

    const Title = ({ nodeData }) => {
        const originalTitle = nodeData.title;
        const key = nodeData.key;
        const icon = nodeData.icon

        let tempTitle = originalTitle;

        if (nodeData.hasOwnProperty('before') && nodeData.hasOwnProperty('after') && nodeData.hasOwnProperty('match') && nodeData.match != "") {
            tempTitle = <span className='search-result-tree'>{nodeData.before}<span className="site-tree-search-value search-result-tree">{nodeData.match}</span>{nodeData.after}</span>;
        }
        else {
            tempTitle = nodeData.title;
        }

        let title = tempTitle

        let hasChildren = "children" in nodeData

        const items = [
            getItem('Renomear', 'rename', <EditOutlined style={displayInline} />),
            getItem('Deletar', 'delete', <DeleteOutlined style={displayInline} />)
        ];

        const handleMenuClick = ({ key, keyPath, domEvent }) => {
            const actionMap = {
                'delete': () => setDeletedObject({id: [nodeData.key]}),
                'rename': () => setEdit({ ...edit, key: nodeData.key, value: nodeData.title, newKey: true }),
            };

            domEvent.stopPropagation();
            const action = keyPath.reverse()[0];
            const actionFunction = actionMap[action];
            if (actionFunction) {
                actionFunction();
            }
            setContextMenu({ ...contextMenu, visible: false, node: null });
            document.removeEventListener('click', onClickOutside);
        }

        const menuProps = {
            items,
            onClick: handleMenuClick,
        };

        const handleKeyDown = (event) => {
            const ENTER = 13;
            const ESCAPE = 27;

            if (event.keyCode === ENTER)
                setEdit({ ...edit, update: true })
            else if (event.keyCode === ESCAPE)
                setEdit({ ...edit, key: null })
        }

        const handleMouseEnter = () => {
            setIsHovered(key);
        };

        const handleMouseLeave = () => {
            setIsHovered(null);
        };

        const handleDoubleClick = () => {
            if (hasChildren)
                setEdit({ ...edit, key: key, value: title, newKey: true })
        }

        const openContextMenu = (e) => {
            e.preventDefault();
            e.stopPropagation();
            setContextMenu({ ...contextMenu, visible: true, node: key });
        }

        return (
            <Row
                gutter={6}
                wrap={false}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onContextMenu={openContextMenu}
                onDoubleClick={handleDoubleClick}
            >
                <Col flex="none">
                    {icon}
                </Col>
                {
                    edit.key === key
                        ?
                        <Col flex="auto">
                            <Input
                                ref={inputRef}
                                size='small'
                                value={edit.value}
                                onChange={(e) => setEdit({ ...edit, value: e.target.value })}
                                onKeyDown={handleKeyDown}
                                autoFocus
                            />
                        </Col>
                        :
                        <Col
                            flex="auto"
                            style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
                        >
                            {title}
                        </Col>
                }
                {
                    hasChildren && (isHovered == key || edit.key === key || (contextMenu.visible && contextMenu.node === key))
                        ?
                        <Col flex="none" style={{ flexShrink: "0" }}>
                                <Dropdown
                                    menu={menuProps}
                                    open={contextMenu.visible && contextMenu.node === key}
                                    onClick={openContextMenu}
                                    autoFocus
                                >
                                    <MoreOutlined
                                        aria-label="add product name to cart"
                                        style={{
                                            display: "inline-flex",
                                            color: contextMenu.node === key ? "#0000EE" : "black",
                                            fontSize: "15px"
                                        }}
                                    />
                                </Dropdown>
                        </Col>
                        :
                        <></>
                }
            </Row>
        )
    }

    const drawContactPath = (obj1, obj2, contact) => {
        let highlightSVG = new HighlightSVG("selected");
        highlightSVG.drawContactPath(obj1, obj2, state.descriptionObjects.objects, contact);
        setContactsData({
            ...contactsData,
            tree: contactsData.tree.map((x) => { return (contact.key === x.key) ? contact :  x }),
        });
    }

    const titleRender = (nodeData) => {
        return <Title nodeData={nodeData} />;
    }


    const onSelect = (selectedKeys, resp) => {
        clearTimeout(doubleClickTimeoutRef.current);

        doubleClickTimeoutRef.current = setTimeout(() => {
            const haveChildren = resp.node.hasOwnProperty('children');

            if (selectedKeys.length > 0 && haveChildren) {
                dispatch(setSelectedContact(resp.node));
                dispatch(setSelectedObject([]));
            }

            if (haveChildren)
                var data = resp.node.children;
            else
                var data = resp.node;

            let highlightSVG = new HighlightSVG("selected");
            highlightSVG.cleanSVG();

            if (haveChildren) {
                highlightSVG.selectSVG(data[0].original_key, state.descriptionObjects.objects);
                highlightSVG.selectSVG(data[1].original_key, state.descriptionObjects.objects);

                drawContactPath(data[0], data[1], resp.node)
            }
            else {
                highlightSVG.selectSVG(data.original_key, state.descriptionObjects.objects);
            }
        }, 200); // Aguarda 200ms para verificar se é um duplo clique
    }

    const onChangeSelectAll = (e) => {
        const checked = e.target.checked;
        const allKeys = traverseTree(contactsData.tree);
        setSelectAllStatus({ ...selectAllStatus, checked: checked });
        setCheckStatus({ ...checkStatus, checkeds: checked ? allKeys : [] });
    };

    const onCheck = (checkedKeys, info) => {
        console.log(checkedKeys, info);
        if(!selectAllStatus.checked)
            setCheckStatus({ ...checkStatus, checkeds: checkedKeys });
    };

    return (
        <>
            { openContactsSearch &&
                <Search
                    style={{
                        backgroundColor: 'white',
                        padding: 8,
                        border: 'none'
                    }}
                    placeholder='Pesquisar'
                    onChange={debounce(onChange, 300)}
                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault()}}
                />
            }

            { selectAllStatus.visible &&
                <div style={{ paddingLeft: 36, lineHeight: 1.5715, paddingBottom: 4 }}>
                    <Checkbox checked={selectAllStatus.checked} onChange={onChangeSelectAll}>
                            <span style={{ paddingLeft: 4 }}>
                                Selecionar tudo
                            </span>
                    </Checkbox>
                </div>
            }

            <Tree
                ref={contactsRef}
                blockNode
                treeData={contactsData.tree}
                showLine={{ showLeafIcon: false }}
                titleRender={titleRender}
                onSelect={onSelect}
                selectedKeys={selectedKeys}
                
                //Check
                onCheck={onCheck}
                checkable={checkStatus.visible}
                checkStrictly={checkStatus.checkStrictly}
                checkedKeys={checkStatus.checkeds}

                //Search
                onExpand={onExpand}
                expandedKeys={expandedKeys}
                autoExpandParent={autoExpandParent}
                style={{ paddingLeft: 12 }}
                
            />
        </>
    );
}

export default ContactsForm;
